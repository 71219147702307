import {
  FETCH_JOBCATEGORY,
  FETCH_JOBS,
  FETCH_WORKERS,
  REGISTER_WORKER,
  SET_MESSAGE,
  HOLIDAYS_PDF,
  CLEAN_FILES,
  MODIFY_WORKER,
  SHOW_EDITWORKER,
  HIDE_EDITWORKER,
  GET_ONEWORKER,
  LABORAL_PROOF,
  UNSET_MESSAGE,
  FETCH_WORKERS_NAMES,
  FETCH_STUDENTS,
  GET_INFO_STUDENT,
} from "../../../../types";

const usuarioReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_WORKER:
    case MODIFY_WORKER:
      return {
        ...state,
        message: {
          msg: action.payload.msg,
          title: "Listo!",
          category: "success",
        },
      };
    case FETCH_JOBS:
      return {
        ...state,
        allJobs: action.payload,
      };
    case FETCH_JOBCATEGORY:
      return {
        ...state,
        allCategories: action.payload,
      };
    case FETCH_WORKERS:
      return {
        ...state,
        workers: action.payload,
      };
    case HOLIDAYS_PDF:
      console.log("vacaciones");
      return {
        ...state,
        pdfHolidays: action.payload,
      };
    case CLEAN_FILES:
      return {
        ...state,
        pdfHolidays: null,
        pdfLaboralProof: null,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case UNSET_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case SHOW_EDITWORKER:
      return {
        ...state,
        modalEdit: true,
      };
    case HIDE_EDITWORKER:
      return {
        ...state,
        modalEdit: false,
      };
    case GET_ONEWORKER:
      return {
        ...state,
        worker: action.payload,
      };
    case FETCH_WORKERS_NAMES:
      return {
        ...state,
        allWorkersNames: action.payload,
      };
    case LABORAL_PROOF:
      return {
        ...state,
        pdfLaboralProof: action.payload,
      };
    case FETCH_STUDENTS:
      return {
        ...state,
        studentsNames: action.payload,
      };
    case GET_INFO_STUDENT:
      return {
        ...state,
        studentInfo: action.payload,
      };
    default:
      return state;
  }
};
export default usuarioReducer;
