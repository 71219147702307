import React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

//import logo from "../../../static/logo.png";
import "bootstrap/dist/css/bootstrap.css";

const ReciboPreview = ({
  estudiante,
  matricula,
  cantidad,
  concepto,
  descuento,
}) => {
  return (
    <div
      style={{
        maxWidth: "18cm",
        minHeight: "10cm",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0.5cm 0.5cm 0.5cm 0.5cm",
        textAlign: "justify",
      }}
    >
      <Card border="secondary">
        <Card.Body>
          {estudiante.trim() === "" ? null : (
            <>
              <Row>
                <Col xs="4" className="h6">
                  <b>Alumno:</b>
                </Col>
                <Col xs="6" className="h6">
                  <u>{estudiante}</u>
                </Col>
              </Row>
              <Row>
                <Col xs="4" className="h6">
                  <b>Matricula: </b>
                </Col>
                <Col xs="6" className="h6">
                  <u>{matricula}</u>
                </Col>
              </Row>
              <Row>
                <Col xs="4" className="h6">
                  <b>Cantidad</b> a pagar:
                </Col>
                <Col xs="6" className="h6">
                  $ <u>{cantidad}</u>
                </Col>
              </Row>
            </>
          )}
          {concepto.trim() === "" ? null : (
            <Row>
              <Col xs="4" className="h6">
                Por <b>Concepto </b> de:
              </Col>
              <Col xs="6" className="h6 border-bottom">
                {concepto}
              </Col>
            </Row>
          )}
          {descuento.trim() === "" ? null : (
            <Row>
              <Col xs="4" className="h6">
                y un <b>Descuento</b> por:
              </Col>
              <Col xs="6" className="h6">
                {descuento}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReciboPreview;
