export const SET_MESSAGE = "SET_MESSAGE";
export const UNSET_MESSAGE = "UNSET_MESSAGE";

export const CLEAN_FILES = "CLEAN_FILES";

//alertas
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

//login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_USER = "GET_USER";
export const CLOSE_SESSION = "CLOSE_SESION";
export const CHANGE_PWD = "CHANGE_PWD";

//pagos
export const GET_PAYMENT = "GET_PAYMENT";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FILTER_STUDENTS = "FILTER_STUDENTS";
export const FETCH_CONCEPTS = "FETCH_CONCEPTS";
export const PAYMENTS_STATISTICS = "PAYMENTS_STATISTICS";
export const VALID_YEARS = "VALID_YEARS";
export const CLEAN_RECEIPT = "CLEAN_RECEIPT";
export const GET_REPORT_FILE = "GET_REPORT_FILE";
export const CLEAN_REPORT = "CLEAN_REPORT";

//conceptos de pago
export const FETCHALL_CONCEPTS = "FETCHALL_CONCEPTS";
export const UPDATE_STATE = "UPDATE_STATE";
export const FETCHALL_CAREERS = "FETCHALL_CAREERS";

//trabajadores
export const REGISTER_WORKER = "REGISTER_WORKER";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBCATEGORY = "FETCH_JOBCATEGORY";
export const FETCH_WORKERS = "FETCH_WORKERS";
export const HOLIDAYS_PDF = "HOLIDAYS_PDF";
export const MODIFY_WORKER = "MODIFY_WORKER";
export const SHOW_EDITWORKER = "SHOW_EDITWORKER";
export const HIDE_EDITWORKER = "HIDE_EDITWORKER";
export const GET_ONEWORKER = "GET_ONEWORKER";
export const LABORAL_PROOF = "LABORAL_PROOF";
export const FETCH_WORKERS_NAMES = "FETCH_WORKERS_NAMES";

//users
export const FETCH_USERS = "FETCH_USERS";
export const ADD_USER = "ADD_USER";
export const GET_ONEUSER = "GET_ONEUSER";
export const MODAL_USER = "MODAL_USER";
export const FETCH_STAFF = "FETCH_STAFF";

//almacen
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const FETCH_ITEMS_CATEGORIES = "FETCH_ITEMS_CATEGORIES";
export const GET_ITEMS_NAMES = "GET_ITEMS_NAMES";
export const FETCH_ITEMS_STOCK = "FETCH_ITEMS_STOCK";
export const FETCH_REGISTERED_ITEMS = "FETCH_REGISTERED_ITEMS";
export const MODIFY_OUT_LIST = "MODIFY_OUT_LIST";
export const CLEAN_OUT_LIST = "CLEAR_OUT_LIST";
export const GET_WAREHOUSE_ACTIVITY = "GET_WAREHOUSE_ACTIVITY";
export const CREATE_WAREHOUSE_RECEIPT = "CREATE_WAREHOUSE_RECEIPT";
export const SET_IVA_VALUE = "SET_IVA_VALUE";
export const SET_PROVEEDORES = "SET_PROVEEDORES";
export const INVOICE_STATUS = "INVOICE_STATUS";
export const CREATE_ITEMS_INPUT_FILE = "CREATE_ITEMS_INPUT_FILE";
export const GET_INVOICE_FILE = "GET_INVOICE_FILE";

//estudiante
export const GET_INFO_STUDENT = "GET_INFO_STUDENT";
