import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../context/alerts/alertContext";
import AuthContext from "../../context/auth/authContext";

import { roles } from "../../config/constants";

import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "../../static/login.css";
import "bootstrap/dist/css/bootstrap.css";

const Login = (props) => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { rol, message, authenticated, firstFlag, iniciarSesion } = authContext;

  //En caso de que las credenciales no sean validas
  useEffect(() => {
    if (authenticated) {
      if (firstFlag) {
        props.history.push("/user/pwd-change");
      } else {
        if (
          rol === roles.JefeAdministrativo ||
          rol === roles.CoordinadorAdministrativo
        ) {
          props.history.push("/recibos/buscar");
        } else if (rol === roles.RecursosHumanos) {
          props.history.push("/rh/trabajadores");
        } else if (rol === roles.Administrador) {
          props.history.push("/admin/usuarios");
        } else if (rol === roles.cae) {
          props.history.push("/cae");
        } else if (rol === roles.Almacen) {
          props.history.push("/almacen/existencias");
        }
      }
    }

    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, authenticated, firstFlag, props.history]);

  //state para iniciar sesión
  const [usuario, setUsuario] = useState({
    rfc: "",
    password: "",
  });

  //extraer de usuario
  const { rfc, password } = usuario;

  const onChangeCredenciales = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  //Cuando el usuario quiere iniciar sesion
  const onSubmitCredenciales = (e) => {
    e.preventDefault();

    //Validar campos vacios
    if (rfc.trim() === "" || password.trim() === "") {
      showAlert("Introduce todos los campos", "Campos Necesarios", "danger");
      return;
    }

    //Pasarlo al action
    iniciarSesion({ rfc, password });
  };

  return (
    <div className="bg">
      <div>
        <Card className="formulario">
          <div className="form-title"> Inicia Sesión</div>
          <Card.Body>
            <Form>
              <Form.Group>
                <Form.Control
                  type="text"
                  id="rfc"
                  name="rfc"
                  value={rfc}
                  placeholder="RFC"
                  onChange={onChangeCredenciales}
                  className="login-input"
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Control
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  placeholder="Contraseña"
                  onChange={onChangeCredenciales}
                  className="login-input"
                />
              </Form.Group>

              <br />
              <Button
                className="login-button btn btn-primary btn-block "
                type="submit"
                onClick={onSubmitCredenciales}
              >
                Entrar
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            {alerta ? (
              <Alert key={alerta.category} variant={alerta.category}>
                <Alert.Heading>{alerta.title}</Alert.Heading>
                {alerta.msg}
              </Alert>
            ) : null}
          </Card.Footer>
          <br />
        </Card>
      </div>
    </div>
  );
};

export default Login;
