import React, { useContext, useEffect, useState } from "react";

import AlertContext from "../../../context/alerts/alertContext";
// import AuthContext from "../../../context/auth/authContext";
import UsuarioContext from "../context/usuarios/usuarioContext";

import CampoInfoStudent from "./CampoInfoStudent";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select/";
import Toast from "react-bootstrap/Toast";
import Card from "react-bootstrap/Card";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

const InfoAlumnos = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  //Extraer la información de autenticacion
  // const authContext = useContext(AuthContext);
  // const { user, authenticatedUser } = authContext;

  const usuarioContext = useContext(UsuarioContext);
  const {
    message,
    studentsNames,
    studentInfo,
    getStudentsNames,
    getIfoStudent,
  } = usuarioContext;

  useEffect(() => {
    getStudentsNames();

    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para iniciar sesión
  const [pagoNuevo, setPagoNuevo] = useState({
    estudiante: "",
    matricula: "",
  });

  //extraer del pago
  const { estudiante, matricula } = pagoNuevo;

  const onChangeSelectAlumno = (option) => {
    const alumno = studentsNames.find(
      (student) => student.matricula === option.value
    );
    setPagoNuevo({
      ...pagoNuevo,
      matricula: alumno.matricula,
      estudiante: alumno.nombre,
    });

    getIfoStudent(alumno.matricula);

    console.log(studentInfo);
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "10%" }}>
        <Row style={{ width: "100%" }}>
          <Col xs="auto">
            <SearchTwoToneIcon />
          </Col>
          <Col
            style={{
              maxWidth: "500px",
              minWidth: "250px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Select
              id="estudiante"
              name="estudiante"
              onChange={onChangeSelectAlumno}
              value={{ value: matricula, label: estudiante }}
              isSearchable={true}
              options={studentsNames.map((student) => ({
                value: student.matricula,
                label: student.nombre,
              }))}
              placeholder="Nombre o matricula del Alumno"
            />
          </Col>
        </Row>
        <Row>
          <br />
        </Row>

        <Row>
          <Col>
            <Card
              style={{
                maxWidth: "700px",
                minWidth: "250px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              variant="Dark"
            >
              <Card.Body>
                {studentInfo ? (
                  <>
                    <CampoInfoStudent
                      title="Matricula"
                      info={studentInfo.matricula}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Nombre"
                      info={studentInfo.nombre}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Primer Apellido"
                      info={studentInfo.primerape}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Segundo Apellido"
                      info={studentInfo.segundoape}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Nivel Academico"
                      info={studentInfo.nivel}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Programa"
                      info={studentInfo.carrera}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Modalidad"
                      info={studentInfo.modalidad}
                      boton="Copiar"
                    />
                    <CampoInfoStudent
                      title="Semestre"
                      info={studentInfo.semestre}
                      boton="Copiar"
                    />
                    {studentInfo.foto ? (
                      <CampoInfoStudent
                        title="Foto"
                        info={studentInfo.foto}
                        boton="Descargar"
                        id={studentInfo.matricula}
                      />
                    ) : (
                      "No hay foto disponible"
                    )}
                  </>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InfoAlumnos;
