import React, { useState, useContext, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ListGroup from "react-bootstrap/ListGroup";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import ItemsContext from "../context/itemsContext";
import UsuarioContext from "../../rh/context/usuarios/usuarioContext";

import ElementoSalidaDetallado from "./ElemetoSalidaDetallado";

import Toast from "react-bootstrap/Toast";

import print from "print-js";

const NuevaSalida = () => {
  const alertContext = useContext(AlertContext);
  const { showAlert, alerta } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser, user } = authContext;

  const itemsContext = useContext(ItemsContext);
  const usuarioContext = useContext(UsuarioContext);

  const { allWorkersNames, getWorkersNames } = usuarioContext;

  const {
    message,
    categorias,
    objetos,
    listItemsOut,
    valeAlmacen,
    getCategoryStock,
    getAllCategories,
    addElementToOutList,
    modifyElementOutList,
    cleanOutList,
    itemsAssignment,
    cleanFiles,
  } = itemsContext;

  useEffect(() => {
    authenticatedUser();
    getWorkersNames();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    if (valeAlmacen) {
      //Build a URL from the file
      const fileURL = URL.createObjectURL(valeAlmacen);
      //Open the URL on new Window
      //window.open(fileURL);
      print(fileURL);
      //print({ printable: recibo, type: "pdf", base64: true });
      cleanFiles();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para las entradas
  const [salidaItems, setSalidaItems] = useState({
    selectedCategory: "",
    selectedWorker: "",
    objeto: "",
    cantidad: "",
  });

  //extraer de la nueva entrada
  const { selectedCategory, selectedWorker, objeto, cantidad } = salidaItems;

  const onChangeFields = (e) => {
    if (e.target.name === "cantidad") {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    setSalidaItems({
      ...salidaItems,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelectCategoria = (option) => {
    setSalidaItems({
      ...salidaItems,
      selectedCategory: option,
    });

    getCategoryStock(option.value);
  };

  const onChangeSelectTrabajador = (option) => {
    getAllCategories();
    setSalidaItems({
      ...salidaItems,
      selectedWorker: option,
      selectedCategory: "",
      objeto: "",
      cantidad: "",
    });

    cleanOutList();
  };

  const onChangeSelectObjeto = (option) => {
    setSalidaItems({
      ...salidaItems,
      objeto: option,
    });
  };

  const onClickAddItem = () => {
    if (selectedCategory === "" || objeto === "" || cantidad === "") {
      showAlert("Llena todos los campos", "Error", "danger");
      return;
    }

    if (cantidad <= 0) {
      showAlert("Ingrese una cantidad valida", "Error", "danger");
      return;
    }

    let existente = null;
    for (let i = 0; i < listItemsOut.length; i++) {
      if (listItemsOut[i].objeto.label === objeto.label) {
        existente = listItemsOut[i];
      }
    }

    if (existente) {
      if (
        Number(existente.cantidad) + Number(cantidad) >
        Number(objeto.cantidad)
      ) {
        showAlert(
          "El almacen no cuenta con esa cantidad",
          "No se puede asignar",
          "danger"
        );
        return;
      } else {
        modifyElementOutList(objeto, Number(cantidad));
      }
    } else {
      if (cantidad > objeto.cantidad) {
        showAlert(
          "El almacen no cuenta con esa cantidad",
          "No se puede asignar",
          "danger"
        );
        return;
      } else {
        addElementToOutList(objeto, cantidad, selectedCategory);
      }
    }

    setSalidaItems({
      ...salidaItems,
      selectedCategory: "",
      objeto: "",
      cantidad: "",
    });
  };

  const onSubmitEntrada = (e) => {
    e.preventDefault();

    if (listItemsOut.length <= 0) {
      showAlert("No hay elementos para guardar", "Error", "danger");
      return;
    }
    if (selectedWorker === "") {
      showAlert("No se seleccionó un trabajador", "Error", "danger");
      return;
    }

    itemsAssignment({
      items: listItemsOut,
      usuario: user,
      trabajador: selectedWorker,
    });

    setSalidaItems({
      ...salidaItems,
      selectedCategory: "",
      objeto: "",
      cantidad: "",
      trabajador: "",
    });

    cleanOutList();
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Trabajador</Form.Label>
              <Select
                id="trabajador"
                name="trabajador"
                onChange={onChangeSelectTrabajador}
                value={selectedWorker}
                isSearchable={true}
                options={allWorkersNames.map((emp) => ({
                  value: emp.rfc,
                  label: emp.nombre,
                }))}
                placeholder="Selecciona"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Categoria</Form.Label>
                  <Select
                    id="categorias"
                    name="categorias"
                    onChange={onChangeSelectCategoria}
                    value={selectedCategory}
                    isSearchable={true}
                    options={categorias.map((cat) => ({
                      value: cat.id,
                      label: cat.categoria,
                    }))}
                    placeholder="Selecciona"
                  />
                </Form.Group>
              </Col>
              <Col xs={3}></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Objeto</Form.Label>
                  <Select
                    id="objetoSelect"
                    name="objetoSelect"
                    onChange={onChangeSelectObjeto}
                    value={objeto}
                    isSearchable={true}
                    options={objetos}
                    placeholder="Selecciona el objeto"
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantidad"
                    name="cantidad"
                    value={cantidad}
                    placeholder={0}
                    onChange={onChangeFields}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <br />
            </Row>
            <Row style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Form.Label>Disponibilidad : {objeto.cantidad}</Form.Label>
            </Row>
          </Col>
          <Col xs={3}>
            <div style={{ width: "100%" }}>
              <Button onClick={onClickAddItem} block>
                Agregar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <hr />
      <div>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col>
            <ListGroup variant="flush">
              {listItemsOut.map((itemData, index) => (
                <ListGroup.Item as="ul" key={index}>
                  <Row style={{ width: "100%" }}>
                    <ElementoSalidaDetallado data={itemData} key={index} />
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          {/* <Col xs={{ span: 3, offset: 4 }}> */}
          <Col xs={3}>
            <Button type="submit" onClick={onSubmitEntrada} block>
              Guardar
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NuevaSalida;
