import React, { Fragment, useContext, useEffect } from "react";
import { Switch } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

import PrivateRoute from "../route/PrivateRoute";

import { roles } from "../../config/constants";

import UserState from "./context/users/userState";

import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

// import NuevoTrabajador from "./components/NuevoTrabajador";
// import ListaTrabajadores from "./components/ListaTrabajadores";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import PeopleAltTwoToneIcon from "@material-ui/icons/PeopleAltTwoTone";
//import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";
import AssignmentIndTwoToneIcon from "@material-ui/icons/AssignmentIndTwoTone";

import Usuarios from "./components/Usuarios";
import AsignacionCargos from "./components/user/AsignacionCargos";

const MainAdmin = () => {
  const menuOptions = [
    {
      opKey: "usuarios",
      opLabel: "Usuarios",
      opPath: "/admin/usuarios",
      opIcon: <PeopleAltTwoToneIcon />,
    },
    // {
    //   opKey: "recibosAdmin",
    //   opLabel: "Recibos",
    //   opPath: "/admin/conceptos-pago",
    //   opIcon: <ListAltTwoToneIcon />,
    // },
    {
      opKey: "aignacionCargos",
      opLabel: "Asignación de Cargos",
      opPath: "/admin/cargos-personal",
      opIcon: <AssignmentIndTwoToneIcon />,
    },
  ];
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  useEffect(() => {
    authenticatedUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <SideBar menuOptions={menuOptions} />
          </Col>
          <Col xs={10}>
            <Header user={user} />
            <div>
              <main>
                <UserState>
                  <Switch>
                    <PrivateRoute
                      path="/admin/usuarios"
                      component={Usuarios}
                      roles={[roles.Administrador]}
                    />
                    <PrivateRoute
                      path="/admin/cargos-personal"
                      component={AsignacionCargos}
                      roles={[roles.Administrador]}
                    />
                  </Switch>
                </UserState>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MainAdmin;
