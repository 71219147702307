import React, { Fragment, useContext, useEffect } from "react";
import { Switch } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

import PrivateRoute from "../route/PrivateRoute";
import { roles } from "../../config/constants";

import AssignmentState from "./context/assignmentState";

import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

import AsignacionesCAE from "./components/AsignacionesCAE";
import ItemsCAE from "./components/ItemsCAE";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
//import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
// import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import LocalHospitalTwoToneIcon from "@material-ui/icons/LocalHospitalTwoTone";

const MainCAE = () => {
  const menuOptions = [
    {
      opKey: "atencionAlumnos",
      opLabel: "Alumnos",
      opPath: "/cae/asignaciones",
      opIcon: <LocalHospitalTwoToneIcon />,
    },
    {
      opKey: "historialCAE",
      opLabel: "Historial",
      opPath: "/cae/prestamo-bien",
      opIcon: <AddTwoToneIcon />,
    },
    {
      opKey: "itemsCAE",
      opLabel: "Catálogo",
      opPath: "/cae/catalogo-items",
      opIcon: <AddTwoToneIcon />,
    },
  ];
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  useEffect(() => {
    authenticatedUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <SideBar menuOptions={menuOptions} />
          </Col>
          <Col xs={10}>
            <Header user={user} />
            <div>
              <main>
                <AssignmentState>
                  <Switch>
                    <PrivateRoute
                      path="/cae/asignaciones"
                      component={AsignacionesCAE}
                      roles={[roles.cae]}
                    />

                    {/* <PrivateRoute
                      path="/cae/prestamo-bien"
                      component={PrestamoBien}
                      roles={[roles.cae]}
                    /> */}
                    <PrivateRoute
                      path="/cae/catalogo-items"
                      component={ItemsCAE}
                      roles={[roles.cae]}
                    />
                  </Switch>
                </AssignmentState>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MainCAE;
