import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";

import ItemsContext from "../context/itemsContext";
import AlertContext from "../../../context/alerts/alertContext";

const InputIVA = () => {
  const itemsContext = useContext(ItemsContext);
  const alertContext = useContext(AlertContext);

  const { alerta, showAlert } = alertContext;
  const { message, iva, getValueIVA, setValueIVA } = itemsContext;

  const [ivaValue, setIVA] = useState("");

  useEffect(() => {
    getValueIVA();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onClickGuardar = (e) => {
    if (ivaValue === "") {
      return;
    }

    setValueIVA(ivaValue);
    getValueIVA();
    setIVA("");
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <Row>
        <Col xs={3}>
          <p className="fs-2">{iva}</p>
        </Col>
        <Col xs={3}>
          <Form>
            <Form.Group>
              <Form.Label>Valor del IVA</Form.Label>
              <Form.Control
                type="text"
                id="ivaValue"
                name="ivaValue"
                value={ivaValue}
                placeholder="%"
                onChange={(e) => setIVA(e.target.value)}
              />
            </Form.Group>
            <Button onClick={onClickGuardar}>Guardar</Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default InputIVA;
