export const roles = {
  Administrador: 0,
  JefeAdministrativo: 7,
  CoordinadorAdministrativo: -2,
  Biblioteca: -3,
  BibliotecaDos: -4,
  Almacen: 9,
  RecursosHumanos: 8,
  cae: -5,
  all: [0, 7, -2, -3, -4, 9, 8],
};

export const categoriasDesechables = [
  "Limpieza",
  "Papeleria",
  "Desechables",
  "Consumibles",
  "Insumos",
];
