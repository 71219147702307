import React, { useState, useContext } from "react";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import Select from "react-select";

import ItemsContext from "../context/itemsContext";

import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";

const ExpandedOutItem = ({ data }) => {
  // console.log(data);

  const itemsContext = useContext(ItemsContext);
  const { deleteElementOutList } = itemsContext;

  let series_disponibles = [];
  for (let i = 0; i < data.objeto.listaids; i++) {
    let identificador = null;
    if (data.objeto.listaids[i].serie) {
      identificador = data.objeto.listaids[i].serie;
    }
    if (data.objeto.listaids[i].inventario) {
      identificador =
        identificador + " | " + data.objeto.listaids[i].inventario;
    }
    series_disponibles.push({
      label: identificador,
      value: data.objeto.listaids[i].id,
    });
  }

  const onChangeSerie = (option) => {};

  const onClickDelete = () => {
    deleteElementOutList(data.objeto.label);
  };
  return (
    <div style={{ paddingLeft: "50px", width: "100%" }}>
      {data ? (
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <Row>
              <Col xs={2}>{data.cantidad}</Col>
              <Col xs={8}>{data.objeto.label}</Col>
              <Col xs={1}>
                <Button variant="outline-danger" onClick={onClickDelete}>
                  <DeleteForeverTwoToneIcon />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {series_disponibles.length > 0 ? (
                  <Form>
                    <Form.Group>
                      <Form.Label>Identificadores disponibles</Form.Label>

                      <Select
                        id="identificadores"
                        name="identificadores"
                        onChange={onChangeSerie}
                        // value={selectedCategory}
                        isSearchable={true}
                        options={series_disponibles}
                        placeholder="Selecciona"
                      />
                    </Form.Group>
                  </Form>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </div>
  );
};

export default ExpandedOutItem;
