import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import ConceptoContext from "../context/conceptos/conceptoContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Select from "react-select";

import "bootstrap/dist/css/bootstrap.css";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const NuevoConcepto = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const conceptoContext = useContext(ConceptoContext);
  const {
    message,
    allconcepts,
    allCareers,
    getAllConcepts,
    getAllCareers,
    saveConcept,
    changeStatus,
  } = conceptoContext;

  const typeOptions = [
    { value: "Pago", label: "Pago" },
    { value: "Descuento", label: "Descuento" },
  ];

  useEffect(() => {
    getAllConcepts();
    getAllCareers();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para iniciar sesión
  const [conceptoNuevo, setConceptoNuevo] = useState({
    concepto: "",
    cantidad: "",
    carrera: null,
    tipo: null,
  });

  const { concepto, cantidad, tipo, carrera } = conceptoNuevo;

  const onChangeFields = (e) => {
    if (e.target.name === "cantidad") {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    setConceptoNuevo({
      ...conceptoNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSelect = (option) => {
    setConceptoNuevo({
      ...conceptoNuevo,
      tipo: option,
    });
  };

  const onChangeSelectCareer = (option) => {
    setConceptoNuevo({
      ...conceptoNuevo,
      carrera: option,
    });
  };

  const onSubmitConcept = (e) => {
    e.preventDefault();
    if (concepto.trim() === "" || cantidad.trim() === "" || !tipo) {
      showAlert("Llena todos los campos", "Error", "danger");
      return;
    }
    let career = "";
    if (carrera) {
      career = carrera.value;
    }
    saveConcept({
      descripcion: concepto,
      cantidad: cantidad,
      tipo: tipo.value,
      carrera: career,
    });
    setConceptoNuevo({
      concepto: "",
      cantidad: "",
      carrera: "",
      tipo: "",
    });
  };

  const onChangeStatus = (e) => {
    e.preventDefault();
    changeStatus(e.target.name);
  };

  const columns = [
    {
      name: "ID",
      selector: "codigo",
      sortable: "true",
      width: "70px",
      center: "true",
    },
    {
      name: "Descripción",
      selector: "description",
      sortable: "true",
      grow: 3,
    },
    {
      name: "Cantidad",
      selector: "quantity",
      sortable: "true",
      //center: "true",
    },
    {
      name: "Tipo",
      selector: "type",
      sortable: "true",
      center: "true",
    },
    {
      name: "Agregado en",
      selector: "created_date",
      sortable: "true",
      center: "true",
    },
    {
      name: "Estado",
      button: true,
      width: "83px",
      cell: (row) => (
        <Button
          variant={row.status === "Activo" ? "outline-dark" : "outline-danger"}
          name={row.codigo}
          onClick={onChangeStatus}
          block
        >
          {row.status}
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "10%" }}>
        <Form>
          <Row>
            <Col>
              <Form.Label className="h5">Nuevo Concepto</Form.Label>
              <Form.Control
                type="text"
                id="concepto"
                name="concepto"
                value={concepto}
                placeholder="Descripción"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Label className="h5">Cantidad</Form.Label>
              <Form.Control
                type="text"
                id="cantidad"
                name="cantidad"
                value={cantidad}
                placeholder="$ 0.0"
                onChange={onChangeFields}
              />
            </Col>
            <Col>
              <Form.Label className="h5">Tipo de concepto</Form.Label>
              <Select
                id="tipo"
                value={tipo}
                options={typeOptions}
                name="tipo"
                onChange={onChangeSelect}
                placeholder="Selecciona"
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Label className="h5">Carrera</Form.Label>
              <Select
                id="carrera"
                value={carrera}
                options={allCareers.map((career) => ({
                  value: career.cve_carrera,
                  label: career.nom_carrera,
                }))}
                name="carrera"
                onChange={onChangeSelectCareer}
                placeholder="Selecciona"
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col style={{ left: "70vw" }}>
              <Button
                style={{ width: "100px" }}
                type="submit"
                onClick={onSubmitConcept}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ paddingTop: "50px" }}>
        <DataTable
          title="Conceptos Existentes"
          columns={columns}
          pagination
          data={allconcepts}
          striped={true}
          responsive={true}
          theme="blue-gray"
        />
      </div>
    </div>
  );
};

export default NuevoConcepto;
