import React, { useState, useContext, useEffect } from "react";

import AuthContext from "../../../context/auth/authContext";

import PagoContext from "../context/pagos/pagoContext";
import AlertContext from "../../../context/alerts/alertContext";

import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import DataTable, { createTheme } from "react-data-table-component";

import {
  columnsTotal,
  columnsTotalCareers,
  columnsTotalConcepts,
  columnsReportPayments,
  conditionalRowStyles,
} from "./columnsSpecification";

import "react-datepicker/dist/react-datepicker.css";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

// const ExpandableCareer = ({ data }) => <h6>{data.carrera}</h6>;
// const ExpandableConcept = ({ data }) => <h6>{data.nombre}</h6>;

const Cortes = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser } = authContext;

  const pagoContext = useContext(PagoContext);
  const {
    paymentsReport,
    getPaymentsReport,
    message,
    reporte,
    getReportFile,
    cleanReport,
  } = pagoContext;

  useEffect(() => {
    // getYears();
    // let today = new Date();
    // getStatistics(today.getFullYear());
    authenticatedUser();
    if (reporte) {
      console.log(reporte);
      //Build a URL from the file
      const periodo =
        getStringDate(fDateInit) + "_al_" + getStringDate(fDateEnd);
      const fileURL = URL.createObjectURL(reporte);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = `reporte_${periodo}.xlsx`;
      a.click();
      URL.revokeObjectURL(fileURL);
      cleanReport();
    }
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, reporte]);

  const currentDate = new Date();

  //state las fechinis
  const [statistics, setStatistics] = useState({
    initDate: currentDate,
    endDate: currentDate,
  });

  const [fileDates, setFileDates] = useState({
    fDateInit: null,
    fDateEnd: null,
  });

  //extraer fechas
  const { initDate, endDate } = statistics;
  const { fDateInit, fDateEnd } = fileDates;

  const getStringDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let newDate = "";

    if (month < 10) {
      newDate = `${day}/0${month}/${year}`;
    } else {
      newDate = `${day}/${month}/${year}`;
    }

    return newDate;
  };

  const onChangeInitDate = (date) => {
    setStatistics({
      ...statistics,
      initDate: date,
    });
  };

  const onChangeEndDate = (date) => {
    setStatistics({
      ...statistics,
      endDate: date,
    });
  };

  const onClickReporte = (e) => {
    if (endDate < initDate) {
      showAlert("No es un intervalo válido de fechas", "Error! ", "danger");
      return;
    }
    let today = new Date();
    if (endDate > today || initDate > today) {
      showAlert(
        "No es un intervalo válido, las fechas no pueden ser mayores a hoy",
        "Error! ",
        "danger"
      );
      return;
    }

    setFileDates({
      ...statistics,
      fDateInit: initDate,
      fDateEnd: endDate,
    });

    getPaymentsReport({
      init_date: getStringDate(initDate),
      end_date: getStringDate(endDate),
    });
  };

  const onClickDownloadReport = (e) => {
    getReportFile({
      init_date: getStringDate(fDateInit),
      end_date: getStringDate(fDateEnd),
    });
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{ paddingTop: "10%", marginRight: "auto", marginLeft: "auto" }}
      >
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text>Fecha Inicio</InputGroup.Text>
              <DatePicker
                selected={initDate}
                onChange={onChangeInitDate}
                closeOnScroll={true}
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text>Fecha Fin</InputGroup.Text>
              <DatePicker
                selected={endDate}
                onChange={onChangeEndDate}
                closeOnScroll={true}
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <Button onClick={onClickReporte}>Generar Reporte</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <br />
        </Row>
      </div>
      <div>
        {paymentsReport ? (
          <>
            <Row style={{ paddingTop: "50px" }}>
              <DataTable
                title="Total del Periodo"
                columns={columnsTotal}
                data={paymentsReport.totales}
                striped={true}
                responsive={true}
                theme="blue-gray"
              />
            </Row>
            <Row style={{ paddingTop: "50px" }}>
              <DataTable
                title="Total por Concepto"
                columns={columnsTotalConcepts}
                data={paymentsReport.concepto}
                striped={true}
                responsive={true}
                theme="blue-gray"
              />
            </Row>
            <Row style={{ paddingTop: "50px" }}>
              <DataTable
                title="Total por Carrera"
                columns={columnsTotalCareers}
                data={paymentsReport.carrera}
                striped={true}
                responsive={true}
                theme="blue-gray"
              />
            </Row>

            <Row style={{ paddingTop: "50px" }}>
              <DataTable
                title="Lista de pagos"
                columns={columnsReportPayments}
                data={paymentsReport.lista_pagos}
                conditionalRowStyles={conditionalRowStyles}
                striped={true}
                responsive={true}
                pagination
                theme="blue-gray"
              />
            </Row>
            <Row>
              <br></br>
            </Row>

            <Button variant="success" onClick={onClickDownloadReport}>
              Exportar xlsx
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Cortes;

//<Fragment>
//      <Container fluid>
//        <Row>
//          <Col xs={7} className="d-none d-sm-block">
//            <div style={{ backgroundColor: "pink" }}>A</div>
//          </Col>
//          <Col xs={3}>
//            <div style={{ backgroundColor: "yellow" }}>B</div>
//          </Col>
//        </Row>
//      </Container>
//    </Fragment>
