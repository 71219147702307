import React, { useContext } from "react";

import Nav from "react-bootstrap/Nav";

import AuthContext from "../../context/auth/authContext";

import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import Dropdown from "react-bootstrap/Dropdown";

const Header = ({ user }) => {
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { closeSession } = authContext;

  let usuario = "Usuario";

  if (user) {
    usuario = user.nombre;
  } else {
    usuario = "Not Logged";
  }

  return (
    <div>
      <Nav
        className="mr-auto justify-content-end"
        style={{ backgroundColor: "#005CAB" }}
      >
        <Dropdown>
          <Dropdown.Toggle style={{ backgroundColor: "#005CAB" }}>
            {" "}
            <AccountCircleTwoToneIcon /> {usuario}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as="button" onClick={() => closeSession()}>
              Cerrar Sesión
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  );
};

export default Header;
