import React, { useContext } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import UsuarioContext from "../context/usuarios/usuarioContext";

const CampoInfoStudent = (data) => {
  const usuarioContext = useContext(UsuarioContext);
  const { getStudentPhoto } = usuarioContext;

  return (
    <Row style={{ paddingTop: "5px" }}>
      <Col xs={3}>{data.title} :</Col>
      <Col xs={6}>{data.info}</Col>
      <Col xs={1}>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={
            data.boton === "Copiar"
              ? () => {
                  navigator.clipboard.writeText(data.info);
                }
              : () => getStudentPhoto()
          }
        >
          {data.boton}
        </Button>
      </Col>
    </Row>
  );
};

export default CampoInfoStudent;
