import React, { useReducer } from "react";

import AlertReducer from "./alertReducer";
import AlertContext from "./alertContext";

import { SHOW_ALERT, HIDE_ALERT } from "../../types";

const AlertState = (props) => {
  const initialState = {
    alerta: null,
  };

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  //Funciones
  const showAlert = (msg, title, category) => {
    dispatch({
      type: SHOW_ALERT,
      payload: {
        msg,
        title,
        category,
      },
    });

    setTimeout(() => {
      dispatch({
        type: HIDE_ALERT,
      });
    }, 3500);
  };

  return (
    <AlertContext.Provider
      value={{
        alerta: state.alerta,
        showAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
