import React, { Fragment, useContext, useEffect } from "react";
import { Switch } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

import PrivateRoute from "../route/PrivateRoute";

import { roles } from "../../config/constants";

import UsuarioState from "./context/usuarios/usuarioState";

import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

import NuevoTrabajador from "./components/NuevoTrabajador";
import ListaTrabajadores from "./components/ListaTrabajadores";
import Vacaciones from "./components/Vacaciones";
import InfoAlumnos from "./components/InfoAlumnos";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";

const MainRH = () => {
  const menuOptions = [
    {
      opKey: "personal",
      opLabel: "Personal",
      opPath: "/rh/trabajadores",
      opIcon: <PersonOutlineTwoToneIcon />,
    },
    {
      opKey: "nuevoTrabajador",
      opLabel: "Registrar",
      opPath: "/rh/agregar-trabajador",
      opIcon: <AddTwoToneIcon />,
    },
    {
      opKey: "oficioVacaciones",
      opLabel: "Oficio de Vacaciones",
      opPath: "/rh/generar-oficio-vac",
      opIcon: <LibraryBooksTwoToneIcon />,
    },
    {
      opKey: "infoStudents",
      opLabel: "información Estudiantes",
      opPath: "/rh/info-students-id",
      opIcon: <LibraryBooksTwoToneIcon />,
    },
  ];
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  useEffect(() => {
    authenticatedUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <SideBar menuOptions={menuOptions} />
          </Col>
          <Col xs={10}>
            <Header user={user} />
            <div>
              <main>
                <UsuarioState>
                  <Switch>
                    <PrivateRoute
                      path="/rh/agregar-trabajador"
                      component={NuevoTrabajador}
                      roles={[roles.RecursosHumanos]}
                    />
                    <PrivateRoute
                      path="/rh/trabajadores"
                      component={ListaTrabajadores}
                      roles={[roles.RecursosHumanos]}
                    />
                    <PrivateRoute
                      path="/rh/generar-oficio-vac"
                      component={Vacaciones}
                      roles={[roles.RecursosHumanos]}
                    />
                    <PrivateRoute
                      path="/rh/info-students-id"
                      component={InfoAlumnos}
                      roles={[roles.RecursosHumanos]}
                    />
                  </Switch>
                </UsuarioState>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MainRH;
