import {
  SET_MESSAGE,
  UNSET_MESSAGE,
  SAVE_CATEGORY,
  FETCH_ITEMS_CATEGORIES,
  GET_ITEMS_NAMES,
  FETCH_ITEMS_STOCK,
  FETCH_REGISTERED_ITEMS,
  MODIFY_OUT_LIST,
  CLEAN_OUT_LIST,
  GET_WAREHOUSE_ACTIVITY,
  CREATE_WAREHOUSE_RECEIPT,
  SET_IVA_VALUE,
  SET_PROVEEDORES,
  INVOICE_STATUS,
  CLEAN_FILES,
  CREATE_ITEMS_INPUT_FILE,
  GET_INVOICE_FILE,
} from "../../../types";

const itemsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_ITEMS_CATEGORIES:
    case SAVE_CATEGORY:
      return {
        ...state,
        categorias: action.payload,
      };
    case GET_ITEMS_NAMES:
      return {
        ...state,
        itemsList: action.payload,
      };
    case FETCH_ITEMS_STOCK:
      return {
        ...state,
        objetos: action.payload,
      };
    case FETCH_REGISTERED_ITEMS:
      return {
        ...state,
        registeredItems: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case UNSET_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case MODIFY_OUT_LIST:
      return {
        ...state,
        listItemsOut: action.payload,
      };
    case CLEAN_OUT_LIST:
      return {
        ...state,
        listItemsOut: [],
      };
    case GET_WAREHOUSE_ACTIVITY:
      return {
        ...state,
        activityList: action.payload,
      };
    case CREATE_WAREHOUSE_RECEIPT:
      return {
        ...state,
        valeAlmacen: action.payload.file,
        message: action.payload.alerta,
      };
    case CLEAN_FILES:
      return {
        ...state,
        valeAlmacen: null,
        inputItemsFile: null,
      };
    case SET_IVA_VALUE:
      return {
        ...state,
        iva: action.payload,
      };
    case SET_PROVEEDORES:
      return {
        ...state,
        proveedores: action.payload,
      };
    case INVOICE_STATUS:
      return {
        ...state,
        invoice_file_path: action.payload,
      };
    case CREATE_ITEMS_INPUT_FILE:
      return {
        ...state,
        inputItemsFile: action.payload.file,
        message: action.payload.alerta,
      };
    case GET_INVOICE_FILE:
      return {
        ...state,
        invoiceFilePDF: action.payload.file,
      };
    default:
      return state;
  }
};

export default itemsReducer;
