import React, { useState, useContext, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import ItemsContext from "../context/itemsContext";

import print from "print-js";

import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const NuevaEntrada = () => {
  const alertContext = useContext(AlertContext);
  const { showAlert, alerta } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser, user } = authContext;

  const itemsContext = useContext(ItemsContext);

  const {
    message,
    categorias,
    itemsList,
    iva,
    inputItemsFile,
    getObjectsNames,
    getAllCategories,
    saveItems,
    proveedores,
    invoice_file_path,
    saveInvoiceFile,
    getValueIVA,
    cleanFiles,
    getProviders,
  } = itemsContext;

  useEffect(() => {
    getAllCategories();
    authenticatedUser();
    getProviders();
    getValueIVA();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    console.log(inputItemsFile);
    if (inputItemsFile) {
      //Build a URL from the file
      const fileURL = URL.createObjectURL(inputItemsFile);
      //Open the URL on new Window
      //window.open(fileURL);
      print(fileURL);
      //print({ printable: recibo, type: "pdf", base64: true });
      cleanFiles();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para las entradas
  const [entradaItems, setEntradaItems] = useState({
    selectedCategory: "",
    objeto: "",
    precioUnitario: "",
    cantidad: "",
    listaEntrada: [],
    newObject: false,
  });

  const [modalState, setModalState] = useState({
    showModal: false,
    numFactura: "",
    archivoFactura: null,
    proveedor: "",
  });

  //extraer de la nueva entrada
  const {
    selectedCategory,
    objeto,
    precioUnitario,
    cantidad,
    listaEntrada,
    newObject,
  } = entradaItems;

  //extraer del modalState
  const { showModal, numFactura, archivoFactura, proveedor } = modalState;

  const onChangeFields = (e) => {
    if (
      e.target.name === "cantidad" ||
      e.target.name === "precioUnitario" ||
      e.target.name === "numFactura"
    ) {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    if (e.target.name === "numFactura") {
      setModalState({
        ...modalState,
        [e.target.name]: e.target.value,
      });
    } else {
      setEntradaItems({
        ...entradaItems,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeDocument = (e) => {
    setModalState({
      ...modalState,
      archivoFactura: e.target.files[0],
    });
  };

  const onChangeSelectProveedores = (option) => {
    setModalState({
      ...modalState,
      proveedor: option,
    });
  };

  const onChangeSelectCategoria = (option) => {
    setEntradaItems({
      ...entradaItems,
      selectedCategory: option,
    });
    getObjectsNames(option.value);
  };

  const onChangeSelectObjeto = (option) => {
    setEntradaItems({
      ...entradaItems,
      objeto: option,
    });
  };

  const onClickDeleteItem = (objeto) => {
    let newList = [];
    for (let i = 0; i < listaEntrada.length; i++) {
      if (listaEntrada[i].objeto === objeto) {
        listaEntrada[i].cantidad =
          Number(listaEntrada[i].cantidad) + Number(cantidad);
      } else {
        newList.push(listaEntrada[i]);
      }
    }

    setEntradaItems({
      ...entradaItems,
      listaEntrada: newList,
    });
  };

  const onClickAddFactura = (e) => {
    setModalState({
      ...modalState,
      showModal: true,
    });
  };

  const onHideModal = (e) => {
    setModalState({
      ...modalState,
      showModal: false,
    });
  };

  const onClickAddItem = (option) => {
    if (
      selectedCategory === "" ||
      objeto === "" ||
      cantidad === "" ||
      precioUnitario === ""
    ) {
      showAlert("Llena todos los campos", "Error", "danger");
      return;
    }

    if (cantidad <= 0) {
      showAlert("Ingrese una cantidad valida", "Error", "danger");
      return;
    }

    let thisObjeto = objeto;
    if (objeto.value) {
      thisObjeto = objeto.value;
    }

    let existente = false;
    for (let i = 0; i < listaEntrada.length; i++) {
      if (listaEntrada[i].objeto === thisObjeto) {
        existente = true;
        listaEntrada[i].cantidad =
          Number(listaEntrada[i].cantidad) + Number(cantidad);
      }
    }
    if (!existente) {
      listaEntrada.push({
        objeto: thisObjeto,
        cantidad,
        precioUnitario,
        selectedCategory,
      });
    }

    setEntradaItems({
      ...entradaItems,
      selectedCategory: "",
      objeto: "",
      cantidad: "",
      precioUnitario: "",
    });
  };

  // Es pa' saber si en un nuevo objeto o no
  const onClickNewItem = (e) => {
    if (newObject === true) {
      if (selectedCategory === "") {
        getObjectsNames("all");
      } else {
        getObjectsNames(selectedCategory.value);
      }
    }
    setEntradaItems({
      ...entradaItems,
      newObject: !newObject,
    });
  };

  const onSubmitEntrada = (e) => {
    e.preventDefault();

    if (listaEntrada.length <= 0) {
      showAlert("No hay elementos para guardar", "Error", "danger");
      return;
    }

    if (proveedor === "" || numFactura.trim() === "" || !archivoFactura) {
      showAlert("Llene los datos de factura", "Error", "danger");
      return;
    }

    let data = new FormData();

    data.append("proveedor", proveedor.value);
    data.append("numFactura", numFactura);
    data.append("file", archivoFactura);

    saveInvoiceFile(data);

    if (invoice_file_path) {
      saveItems({
        items: listaEntrada,
        usuario: user,
        proveedor: proveedor.value,
        numFactura: numFactura,
        pathFile: invoice_file_path,
      });

      setEntradaItems({
        ...entradaItems,
        listaEntrada: [],
      });
      setModalState({
        numFactura: "",
        archivoFactura: null,
        proveedor: "",
      });
    }
  };

  const columns = [
    {
      name: "Cantidad",
      selector: "cantidad",
      sortable: "true",
      width: "100px",
      center: "true",
    },
    {
      name: "Descripción",
      selector: "objeto",
      sortable: "true",
    },
    {
      name: "Precio",
      selector: "precioUnitario",
      sortable: "true",
      center: "true",
      cell: (row) => {
        return <>{parseFloat(row.precioUnitario).toFixed(2)}</>;
      },
    },
    {
      name: "Subtotal",
      width: "100px",
      cell: (row) => {
        return (
          <>{(Number(row.cantidad) * Number(row.precioUnitario)).toFixed(2)}</>
        );
      },
    },

    {
      name: "IVA",
      width: "100px",
      cell: (row) => {
        return (
          <>
            {iva
              ? (
                  (iva / 100) *
                  Number(row.cantidad) *
                  Number(row.precioUnitario)
                ).toFixed(2)
              : null}
          </>
        );
      },
    },
    {
      name: "-",
      button: true,
      width: "50px",
      cell: (row) => {
        return (
          <Button
            size="sm"
            name={row.index}
            onClick={() => onClickDeleteItem(row.objeto)}
            variant="outline-danger"
            data-tag="allowRowEvents"
          >
            <DeleteForeverTwoToneIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <Form>
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Categoria</Form.Label>
                  <Select
                    id="categorias"
                    name="categorias"
                    onChange={onChangeSelectCategoria}
                    value={selectedCategory}
                    isSearchable={true}
                    options={categorias.map((cat) => ({
                      value: cat.id,
                      label: cat.categoria,
                    }))}
                    placeholder="Selecciona"
                  />
                </Form.Group>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Objeto</Form.Label>
                  {newObject ? (
                    <Form.Control
                      type="text"
                      id="objeto"
                      name="objeto"
                      value={objeto}
                      placeholder="Escribe el nombre del nuevo objeto ..."
                      onChange={onChangeFields}
                    />
                  ) : (
                    <Select
                      id="objetoSelect"
                      name="objetoSelect"
                      onChange={onChangeSelectObjeto}
                      value={objeto}
                      isSearchable={true}
                      options={itemsList.map((obj) => ({
                        value: obj,
                        label: obj,
                      }))}
                      placeholder="Selecciona el objeto"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col
                xs={1}
                style={{
                  height: "100%",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Button variant="link" onClick={onClickNewItem}>
                  {newObject ? "Lista" : "Nuevo"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantidad"
                    name="cantidad"
                    value={cantidad}
                    placeholder={0}
                    onChange={onChangeFields}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Precio Unitario</Form.Label>
                  <Form.Control
                    type="text"
                    id="precioUnitario"
                    name="precioUnitario"
                    value={precioUnitario}
                    placeholder={0}
                    onChange={onChangeFields}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col span={3} xs={3} style={{ height: "100%" }}>
            <div style={{ width: "100%" }}>
              <Button onClick={onClickAddItem} block>
                Agregar
              </Button>
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "95%",
              }}
            >
              <Button onClick={onClickAddFactura}>Factura</Button>
            </div>
          </Col>
        </Row>
      </Form>
      <hr />
      <div>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col>
            <DataTable
              columns={columns}
              data={listaEntrada}
              striped={true}
              responsive={true}
            />
          </Col>
          <Col xs={3}>
            <Row>
              <br />
            </Row>
            <Row>
              <Button type="submit" onClick={onSubmitEntrada} block>
                Guardar
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={onHideModal}
          backdrop="static"
          keyboard={false}
          centered
          size="sm"
        >
          <Modal.Header closeButton={true} onHide={onHideModal}>
            <Modal.Title>Info Factura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Select
                    id="proveedor"
                    name="proveedor"
                    onChange={onChangeSelectProveedores}
                    value={proveedor}
                    isSearchable={true}
                    options={proveedores.map((provider) => ({
                      value: provider.providerID,
                      label: provider.providerName,
                    }))}
                    placeholder="Selecciona un proveedor"
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    id="numFactura"
                    name="numFactura"
                    value={numFactura}
                    placeholder="Número de factura"
                    onChange={onChangeFields}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="file"
                    id="archivoFactura"
                    name="archivoFactura"
                    onChange={onChangeDocument}
                    accept=".pdf"
                    size="sm"
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button onClick={onHideModal}>OK</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default NuevaEntrada;
