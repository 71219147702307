import React, { useContext, useState, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import PagoContext from "../context/pagos/pagoContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import "bootstrap/dist/css/bootstrap.css";

const CancelarPago = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const pagoContext = useContext(PagoContext);
  const { message, payment, getOnePayment, deletePayment } = pagoContext;

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const [cancelacion, setCancelacion] = useState({
    pattern: "",
  });

  const { pattern } = cancelacion;

  const onChangeSearch = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    setCancelacion({
      ...cancelacion,
      pattern: e.target.value,
    });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getOnePayment(pattern);
    setCancelacion({
      ...cancelacion,
      pattern: "",
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    deletePayment(payment.folio);
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "10%",
          width: "30%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "350px",
        }}
      >
        <Form>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="folio"
                name="folio"
                value={pattern}
                placeholder="Buscar"
                onChange={onChangeSearch}
              />
            </Col>
            <Col xs={1}>
              <Button variant="link" type="submit" onClick={onSubmitSearch}>
                <SearchTwoToneIcon />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ paddingTop: "50px" }}>
        {payment ? (
          <Card
            bg="secondary"
            text="white"
            style={{ width: "70%", marginLeft: "auto", marginRight: "auto" }}
            className="mb-2"
          >
            <Card.Header>
              Registro elaborado el {payment.fecha_pago}
            </Card.Header>
            <Card.Title className="text-center">
              Folio #{payment.folio}
            </Card.Title>
            <Card.Body>
              <Row>
                <Col xs="2">
                  <b>Alumno: </b>
                </Col>
                <Col>{payment.alumno}</Col>
              </Row>
              <Row>
                <Col xs="2">
                  <b>Matricula: </b>
                </Col>
                <Col>{payment.matricula}</Col>
              </Row>
              <Row>
                <Col xs="2">
                  <b>Concepto: </b>
                </Col>
                <Col>{payment.cobro}</Col>
              </Row>
              <Row>
                <Col xs="2">
                  <b>Descuento: </b>
                </Col>
                <Col>{payment.descuento}</Col>
              </Row>
              <Row>
                <Col xs="2">
                  <b>Cantidad: </b>
                </Col>
                <Col>$ {payment.cantidad_pagada}</Col>
              </Row>
              <Row>
                <Col xs="2">
                  <b>Elaboró: </b>
                </Col>
                <Col>{payment.usuario_nombre}</Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-center">
              <Button variant="danger" type="button" onClick={onClickCancel}>
                Cancelar
              </Button>
            </Card.Footer>
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export default CancelarPago;
