import React, { useContext, useEffect, useState } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import UsuarioContext from "../context/usuarios/usuarioContext";

import ExpandedWorker from "./ExpandedWorker";
import ModalEditTrabajador from "./ModalEditTrabajador";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";

import "bootstrap/dist/css/bootstrap.css";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const conditionalRowStyles = [
  {
    when: (row) => row.activo === false,
    style: {
      //backgroundColor: "#FCA3A3",
      color: "#BD0202",
      // "&:hover": {
      //   cursor: "not-allowed",
      // },
    },
  },
];

const ListaTrabajadores = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const usuarioContext = useContext(UsuarioContext);
  const {
    message,
    workers,
    modalEdit,
    worker,
    pdfLaboralProof,
    getWorkersList,
    cleanFiles,
    showModalEdit,
    hideModalEdit,
    getOneWorker,
    getLaboralProofPDF,
  } = usuarioContext;

  useEffect(() => {
    getWorkersList("initial-search");

    if (pdfLaboralProof) {
      console.log("pdf");
      //Build a URL from the file
      const fileURL = URL.createObjectURL(pdfLaboralProof);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = "ConstanciaLaboral.pdf";
      a.click();
      URL.revokeObjectURL(fileURL);
      //Open the URL on new Window
      //window.open(fileURL);
      //print(fileURL);
      //print({ printable: recibo, type: "pdf", base64: true });
      cleanFiles();
    }
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfLaboralProof, message]);

  const [stateTrabajadores, setStateTrabajadores] = useState({
    pattern: "",
  });

  const { pattern } = stateTrabajadores;

  const onChangeSearch = (e) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      pattern: e.target.value,
    });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getWorkersList(pattern);
    setStateTrabajadores({
      ...stateTrabajadores,
      pattern: "",
    });
  };

  const onHideModal = (e) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      modalHolidays: false,
      selectedWorker: null,
    });
    hideModalEdit();
  };

  const onShowModalEdit = (id) => {
    //console.log(row);
    getOneWorker(id);
    showModalEdit();
  };

  const onClickConstancia = (rfc) => {
    getLaboralProofPDF(rfc);
  };

  const columns = [
    {
      name: "RFC",
      selector: "rfc",
      sortable: "true",
      width: "200px",
      center: "true",
    },
    {
      name: "Nombre",
      selector: "nombre_completo",
      sortable: "true",
      grow: 2,
    },
    {
      name: "Cargo",
      selector: "puesto",
      sortable: "true",
      center: "true",
    },
    {
      name: "e-mail",
      selector: "correo",
      sortable: "true",
      center: "true",
      grow: 2,
    },
    {
      name: "-",
      button: true,
      width: "100px",
      cell: (row) => {
        //console.log(row);
        if (row.rol !== 0) {
          return (
            <Button
              size="sm"
              name={row.rfc}
              id={row.rfc}
              variant="outlined-primary"
              onClick={() => onShowModalEdit(row.id)}
              block
            >
              <EditTwoToneIcon />
            </Button>
          );
        }
      },
    },
    {
      name: "-",
      button: true,
      width: "100px",
      cell: (row) => {
        //console.log(row);
        return (
          <Button
            size="sm"
            name={row.rfc}
            id={row.rfc}
            variant="outlined-primary"
            onClick={() => onClickConstancia(row.rfc)}
            block
          >
            <LibraryBooksTwoToneIcon />
          </Button>
        );
      },
    },
    // {
    //   name: "Estado",
    //   button: true,
    //   width: "70px",
    //   cell: (row) => {
    //     //console.log(row);
    //     if (row.rol !== 0) {
    //       return (
    //         <Button
    //           size="sm"
    //           variant={row.activo ? "outline-dark" : "outline-danger"}
    //           name={row.rfc}
    //           onClick={onChangeStatusWorker}
    //           block
    //         >
    //           {row.activo ? "Activo" : "Inactivo"}
    //         </Button>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "10%",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "350px",
        }}
      >
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    id="pattern"
                    name="pattern"
                    value={pattern}
                    placeholder="Buscar"
                    onChange={onChangeSearch}
                  />
                </Col>
                <Col>
                  <Button type="submit" onClick={onSubmitSearch}>
                    <SearchTwoToneIcon />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <DataTable
          columns={columns}
          keyField="rfc"
          pagination
          data={workers}
          striped={true}
          responsive={true}
          theme="blue-gray"
          conditionalRowStyles={conditionalRowStyles}
          expandableRows={true}
          //   // expandOnRowClicked={true}
          //   // expandableRowsHideExpander={true}
          expandableRowsComponent={<ExpandedWorker />}
        />
      </div>
      {worker ? (
        <div>
          <Modal
            show={modalEdit}
            onHide={onHideModal}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-70w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <Modal.Header
              closeButton={true}
              onHide={onHideModal}
            ></Modal.Header>
            <Modal.Body>
              <Row>
                <ModalEditTrabajador name="modalEdit" trabajador={worker} />
              </Row>
              <Row>
                <br />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button
                    onClick={onHideModal}
                    variant="outline-danger"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default ListaTrabajadores;
