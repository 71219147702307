import React, { useReducer } from "react";

import ItemsContext from "./itemsContext";
import ItemsReducer from "./itemsReducer";

import server from "../../../config/axios";

import {
  SET_MESSAGE,
  UNSET_MESSAGE,
  SAVE_CATEGORY,
  FETCH_ITEMS_CATEGORIES,
  GET_ITEMS_NAMES,
  FETCH_ITEMS_STOCK,
  FETCH_REGISTERED_ITEMS,
  MODIFY_OUT_LIST,
  CLEAN_OUT_LIST,
  GET_WAREHOUSE_ACTIVITY,
  CREATE_WAREHOUSE_RECEIPT,
  SET_IVA_VALUE,
  SET_PROVEEDORES,
  INVOICE_STATUS,
  CLEAN_FILES,
  CREATE_ITEMS_INPUT_FILE,
  GET_INVOICE_FILE,
} from "../../../types";
const ItemsState = (props) => {
  const initialState = {
    registeredItems: [],
    itemsList: [],
    categorias: [],
    objetos: [],
    listItemsOut: [],
    message: null,
    activityList: [],
    valeAlmacen: null,
    iva: null,
    proveedores: [],
    invoice_file_path: null,
    invoiceFilePDF: null,
    inputItemsFile: null,
  };

  const [state, dispatch] = useReducer(ItemsReducer, initialState);

  const cleanAlertMessages = () => {
    setTimeout(() => {
      dispatch({
        type: UNSET_MESSAGE,
      });
    }, 3500);
  };

  // functions
  const getAllCategories = async () => {
    try {
      const response = await server.get("/itemsCategories");
      dispatch({
        type: FETCH_ITEMS_CATEGORIES,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo obtener las categorias";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getCategoryStock = async (category) => {
    try {
      const response = await server.get(`/getItemsStockByCategory/${category}`);
      dispatch({
        type: FETCH_ITEMS_STOCK,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los objetos";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getObjectsNames = async (category) => {
    try {
      const response = await server.get(`/getListOfItemsNames/${category}`);

      dispatch({
        type: GET_ITEMS_NAMES,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo guardar la categoria";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getRegisteredItems = async (category) => {
    try {
      const response = await server.get(`/getRegisteredItems/${category}`);
      dispatch({
        type: FETCH_REGISTERED_ITEMS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los objetos";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const saveNewCategory = async (name) => {
    try {
      const response = await server.post("/itemsCategories", [name]);
      dispatch({
        type: SAVE_CATEGORY,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo guardar la categoria";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const saveInvoiceFile = async (data) => {
    try {
      const response = await server.post("/saveInvoiceFile", data);
      dispatch({
        type: INVOICE_STATUS,
        payload: response.data.path,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo guardar el archivo de la factura";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const saveItems = async (items) => {
    try {
      const response = await server({
        method: "post",
        url: "/saveNewItems",
        data: items,
        responseType: "blob",
      });

      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });

      const alerta = {
        msg: "Salida de articulos, guaradado con éxito",
        title: "Listo! ",
        category: "success",
      };

      dispatch({
        type: CREATE_ITEMS_INPUT_FILE,
        payload: { file: file, alerta: alerta },
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error de servidor";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const addElementToOutList = (elemento, cantidad, categoria) => {
    let newListOut = state.listItemsOut.map((element) => element);

    newListOut.push({
      objeto: elemento,
      cantidad: cantidad,
      categoria: categoria,
    });
    dispatch({
      type: MODIFY_OUT_LIST,
      payload: newListOut,
    });

    console.log(state.listItemsOut);
  };

  const modifyElementOutList = (elemento, cantidad) => {
    let newListOut = state.listItemsOut.map((element) => {
      if (element.objeto.label === elemento.label) {
        const anterior = Number(element.cantidad);
        element.cantidad = anterior + cantidad;
      }
      return element;
    });

    dispatch({
      type: MODIFY_OUT_LIST,
      payload: newListOut,
    });
  };

  const deleteElementOutList = (elemento) => {
    let newListOut = [];

    for (let i = 0; i < state.listItemsOut.length; i++) {
      if (elemento !== state.listItemsOut[i].objeto.label) {
        newListOut.push(state.listItemsOut[i]);
      }
    }

    dispatch({
      type: MODIFY_OUT_LIST,
      payload: newListOut,
    });
  };

  const cleanOutList = () => {
    dispatch({
      type: CLEAN_OUT_LIST,
    });
  };

  const itemsAssignment = async (data) => {
    try {
      const response = await server({
        method: "post",
        url: "/itemsAssignmentToWorker",
        data: data,
        responseType: "blob",
      });
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });

      const alerta = {
        msg: "Salida de articulos, guaradado con éxito",
        title: "Listo! ",
        category: "success",
      };

      dispatch({
        type: CREATE_WAREHOUSE_RECEIPT,
        payload: { file: file, alerta: alerta },
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al guardar los cambios";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getInvoiceFilePDF = async (folio) => {
    try {
      const response = await server({
        method: "get",
        url: `/getInvoiceFilePDF/${folio}`,
        responseType: "blob",
      });
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });

      const alerta = {
        msg: "Salida de articulos, guaradado con éxito",
        title: "Listo! ",
        category: "success",
      };

      dispatch({
        type: GET_INVOICE_FILE,
        payload: { file: file, alerta: alerta },
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al guardar los cambios";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const cleanFiles = () => {
    dispatch({
      type: CLEAN_FILES,
    });
  };

  const getActivityReport = async (interval) => {
    try {
      const response = await server.get(`/warehouseActivityReport`, {
        params: interval,
      });
      // console.log(response.data);
      dispatch({
        type: GET_WAREHOUSE_ACTIVITY,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los datos del reporte";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
    cleanAlertMessages();
  };

  const getValueIVA = async () => {
    try {
      const response = await server.get(`/valueIVA`);
      // console.log(response.data);
      dispatch({
        type: SET_IVA_VALUE,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener el valor del IVA";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
    cleanAlertMessages();
  };

  const setValueIVA = async (valueIVA) => {
    try {
      const response = await server.post(`/valueIVA`, [valueIVA]);
      // console.log(response.data);
      dispatch({
        type: SET_IVA_VALUE,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al establecer el valor del IVA";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
    cleanAlertMessages();
  };

  const getProviders = async () => {
    try {
      const response = await server.get(`/warehouseProviders`);
      // console.log(response.data);
      dispatch({
        type: SET_PROVEEDORES,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los proveedores";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
    cleanAlertMessages();
  };

  const addProvider = async (data) => {
    try {
      const response = await server.post(`/warehouseProviders`, data);
      // console.log(response.data);
      dispatch({
        type: SET_PROVEEDORES,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los proveedores";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
    cleanAlertMessages();
  };

  return (
    <ItemsContext.Provider
      value={{
        message: state.message,
        itemsList: state.itemsList,
        categorias: state.categorias,
        objetos: state.objetos,
        listItemsOut: state.listItemsOut,
        registeredItems: state.registeredItems,
        activityList: state.activityList,
        valeAlmacen: state.valeAlmacen,
        iva: state.iva,
        proveedores: state.proveedores,
        invoice_file_path: state.invoice_file_path,
        inputItemsFile: state.inputItemsFile,
        invoiceFilePDF: state.invoiceFilePDF,
        getAllCategories,
        getObjectsNames,
        saveNewCategory,
        saveItems,
        getCategoryStock,
        addElementToOutList,
        modifyElementOutList,
        deleteElementOutList,
        getRegisteredItems,
        cleanOutList,
        itemsAssignment,
        getActivityReport,
        cleanFiles,
        getValueIVA,
        setValueIVA,
        getProviders,
        addProvider,
        saveInvoiceFile,
        getInvoiceFilePDF,
      }}
    >
      {props.children}
    </ItemsContext.Provider>
  );
};

export default ItemsState;
