import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../../../context/alerts/alertContext";
import UserContext from "../../context/users/userContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import { roles } from "../../../../config/constants";

import "bootstrap/dist/css/bootstrap.css";

const AsignacionCargos = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const userContext = useContext(UserContext);
  const {
    message,
    staff,
    jobs,
    getJobs,
    getStaffList,
    jobAssignment,
  } = userContext;

  const allowedJobs = [
    { label: "Jefe Administrativo", value: roles.JefeAdministrativo },
    {
      label: "Coordinador Administrativo",
      value: roles.CoordinadorAdministrativo,
    },
    { label: "Biblioteca", value: roles.Biblioteca },
    { label: "Almacen", value: roles.Almacen },
    { label: "Recursos Humanos", value: roles.RecursosHumanos },
  ];

  useEffect(() => {
    getStaffList();
    getJobs();

    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const listJobs = (personRol) => {
    getJobs();
    const notAllowedJobs = jobs.map((job) => ({
      value: job.id,
      label: job.cargo,
    }));
    let jobsList = [...notAllowedJobs, ...allowedJobs];
    if (personRol === -1) {
      jobsList = [...jobsList, { value: 0, label: "CAE" }];
    }
    return jobsList;
  };

  //state para asignacion
  const [asignacion, setAsignacion] = useState({
    selectedPerson: null,
    selectedJob: null,
    jobsDissabled: true,
    jobOptions: allowedJobs,
  });

  //extraer de la asignacion
  const { selectedPerson, selectedJob, jobsDissabled, jobOptions } = asignacion;

  const onChangeSelectPersonal = (option) => {
    setAsignacion({
      ...asignacion,
      selectedPerson: option,
      jobsDissabled: false,
      jobOptions: listJobs(option.rol),
    });
  };

  const onChangeSelectCargo = (option) => {
    setAsignacion({
      ...asignacion,
      selectedJob: option,
    });
  };

  const onSubmitAsignacion = (e) => {
    e.preventDefault();

    if (!selectedPerson || !selectedJob) {
      console.log("doing nothing!");
      return;
    }

    jobAssignment({ person: selectedPerson.value, job: selectedJob });

    setAsignacion({
      selectedPerson: null,
      selectedJob: null,
      jobsDissabled: true,
      jobOptions: listJobs(0),
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: "15px" }}>
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: "relative",
            minHeight: "50px",
          }}
        >
          {alerta ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Toast
                style={
                  alerta.category === "success"
                    ? { backgroundColor: "#62FF64", opacity: "0.7" }
                    : { backgroundColor: "#FF6262", opacity: "0.7" }
                }
              >
                <Toast.Body>
                  <b>{alerta.title}</b>: {alerta.msg}
                </Toast.Body>
              </Toast>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          minWidth: "350px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10%",
        }}
      >
        <Form>
          <Form.Group>
            <Form.Label>Personal</Form.Label>
            <Col>
              <Select
                id="persona"
                name="persona"
                onChange={onChangeSelectPersonal}
                placeholder="Seleccione una persona"
                isSearchable={true}
                value={selectedPerson}
                options={staff.map((person) => ({
                  value: person.id,
                  label: person.nombre,
                  rol: person.rol,
                }))}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label>Cargo</Form.Label>
            <Col>
              <Select
                id="puesto"
                name="puesto"
                onChange={onChangeSelectCargo}
                placeholder="Selecciona cargo"
                isSearchable={true}
                value={selectedJob}
                isDisabled={jobsDissabled}
                options={jobOptions.map((job) => ({
                  value: job.value,
                  label: job.label,
                }))}
              />
            </Col>
          </Form.Group>
          <Row>
            <br />
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 5 }}>
              <Button
                // style={{ width: "100px"}}
                type="submit"
                onClick={onSubmitAsignacion}
              >
                Asignar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AsignacionCargos;
