import server from "./axios";

const tokenAuth = (token) => {
  if (token) {
    server.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete server.defaults.headers.common["Authorization"];
  }
};

export default tokenAuth;
