import React from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import CategoriasItems from "./CategoriasItems";
import InputIVA from "./InputIVA";
import Proveedores from "./Proveedores";

const CatalogosAlmacen = () => {
  return (
    <>
      <Tabs defaultActiveKey="categorias-items" id="tab-catalogos-almacen">
        <Tab eventKey="categorias-items" title="Categorias">
          <CategoriasItems />
        </Tab>
        <Tab eventKey="proveedores" title="Proveedores">
          <Proveedores />
        </Tab>
        <Tab eventKey="variables" title="Variables">
          <InputIVA />
        </Tab>
      </Tabs>
    </>
  );
};

export default CatalogosAlmacen;
