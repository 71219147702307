import {
  FETCH_USERS,
  ADD_USER,
  GET_ONEUSER,
  SET_MESSAGE,
  MODAL_USER,
  FETCH_STAFF,
  FETCH_JOBS,
  MODIFY_WORKER,
  UNSET_MESSAGE,
} from "../../../../types";

const userReducer = (state, action) => {
  switch (action.type) {
    case ADD_USER:
    case MODIFY_WORKER:
      return {
        ...state,
        message: {
          msg: action.payload,
          title: "Listo!",
          category: "success",
        },
      };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ONEUSER:
      return {
        ...state,
        user: action.payload,
      };
    case MODAL_USER:
      return {
        ...state,
        showModal: action.payload,
      };
    case FETCH_STAFF:
      return {
        ...state,
        staff: action.payload,
      };
    case FETCH_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case UNSET_MESSAGE:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default userReducer;
