import {
  SET_MESSAGE,
  UNSET_MESSAGE,
  FETCH_STUDENTS,
  FILTER_STUDENTS,
} from "../../../types";

const assignmentReducer = (state, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case UNSET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case FETCH_STUDENTS:
      return {
        ...state,
        students: action.payload,
      };
    case FILTER_STUDENTS:
      return {
        ...state,
        filteredStudents: action.payload,
      };
    default:
      return state;
  }
};

export default assignmentReducer;
