import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import UserContext from "../context/users/userContext";

import FormEditUser from "./user/FormEditUser";
import FormNewUser from "./user/FormNewUser";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
//import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";

import "bootstrap/dist/css/bootstrap.css";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const conditionalRowStyles = [
  {
    when: (row) => row.activo === false,
    style: {
      //backgroundColor: "#FCA3A3",
      color: "#BD0202",
      // "&:hover": {
      //   cursor: "not-allowed",
      // },
    },
  },
];

const Usuarios = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const userContext = useContext(UserContext);
  const {
    message,
    showModal,
    users,
    user,
    getUsers,
    changeStatusUser,
    getUser,
    changeStateModalUser,
  } = userContext;

  useEffect(() => {
    getUsers("initial-search");
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const [stateUsers, setStateUsers] = useState({
    pattern: "",
    editUser: false,
  });

  const { pattern, editUser } = stateUsers;

  const onChangeSearch = (e) => {
    setStateUsers({
      ...stateUsers,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (pattern.trim() === "") {
      return;
      // getUsers("initial-search");
    }
    getUsers(pattern);
    setStateUsers({
      ...stateUsers,
      pattern: "",
    });
  };

  const onChangeStatusUser = (e) => {
    e.preventDefault();
    changeStatusUser(e.target.name);
  };

  const onAddUser = (e) => {
    e.preventDefault();
    setStateUsers({
      ...stateUsers,
      editUser: false,
    });
    changeStateModalUser(true);
  };

  const onEditUser = (id) => {
    setStateUsers({
      ...stateUsers,
      editUser: true,
    });
    getUser(id);
    changeStateModalUser(true);
  };

  const onHideModalUser = () => {
    setStateUsers({
      ...stateUsers,
      editUser: false,
    });
    changeStateModalUser(false);
  };

  const columns = [
    {
      name: "RFC",
      selector: "rfc",
      sortable: "true",
      width: "200px",
      center: "true",
    },
    {
      name: "Nombre",
      selector: "nombre_completo",
      sortable: "true",
      grow: 2,
    },
    {
      name: "Cargo",
      selector: "puesto",
      sortable: "true",
      center: "true",
    },
    {
      name: "e-mail",
      selector: "correo",
      sortable: "true",
      center: "true",
      grow: 2,
    },
    {
      name: "-",
      button: true,
      width: "100px",
      cell: (row) => {
        //console.log(row);
        if (row.rol !== 0) {
          return (
            <Button
              size="sm"
              name={row.rfc}
              id={row.rfc}
              variant="outlined-primary"
              onClick={() => onEditUser(row.id)}
              block
            >
              <EditTwoToneIcon />
            </Button>
          );
        }
      },
    },

    {
      name: "Estado",
      button: true,
      width: "70px",
      cell: (row) => {
        //console.log(row);
        if (row.rol !== 0) {
          return (
            <Button
              size="sm"
              variant={row.activo ? "outline-dark" : "outline-danger"}
              name={row.rfc}
              onClick={onChangeStatusUser}
              block
            >
              {row.activo ? "Activo" : "Inactivo"}
            </Button>
          );
        }
      },
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "10%",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "350px",
        }}
      >
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    id="pattern"
                    name="pattern"
                    value={pattern}
                    placeholder="Buscar"
                    onChange={onChangeSearch}
                  />
                </Col>
                <Col sm={3}>
                  <Button type="submit" onClick={onSubmitSearch}>
                    <SearchTwoToneIcon />
                  </Button>
                </Col>
                <Col sm={1}>
                  <Button type="submit" onClick={onAddUser}>
                    <PersonAddTwoToneIcon />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <DataTable
          columns={columns}
          keyField="rfc"
          pagination
          data={users}
          striped={true}
          responsive={true}
          theme="blue-gray"
          conditionalRowStyles={conditionalRowStyles}
          // expandableRows={true}
          // //   // expandOnRowClicked={true}
          // //   // expandableRowsHideExpander={true}
          // expandableRowsComponent={<ExpandedWorker />}
        />
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={onHideModalUser}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-70w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header
            closeButton={true}
            onHide={onHideModalUser}
          ></Modal.Header>
          <Modal.Body>
            {editUser ? (
              <>
                <Row>
                  <FormEditUser name="modalEdit" userInfo={user} />
                </Row>
                <Row>
                  <br />
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <FormNewUser name="modalNew" />
                </Row>
                <Row>
                  <br />
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button
                  onClick={onHideModalUser}
                  variant="outline-danger"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Usuarios;
