import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import ItemsContext from "../context/itemsContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

const Proveedores = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const itemsContext = useContext(ItemsContext);
  const { message, proveedores, getProviders, addProvider } = itemsContext;

  useEffect(() => {
    getProviders();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para el proveedor nuevo
  const [newProvider, setNewProvider] = useState({
    providerName: "",
    providerRFC: "",
    providerAdress: "",
    providerTel: "",
    providerEmail: "",
  });

  const {
    providerName,
    providerRFC,
    providerAdress,
    providerTel,
    providerEmail,
  } = newProvider;

  const onChangeFields = (e) => {
    if (e.target.name === "providerTel") {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    setNewProvider({
      ...newProvider,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitProveedor = (e) => {
    e.preventDefault();
    if (
      providerName.trim() === "" ||
      providerRFC.trim() === "" ||
      providerTel.trim() === "" ||
      providerAdress.trim() === "" ||
      providerEmail.trim() === ""
    ) {
      showAlert("Ingrese los datos completos", "Error", "danger");
      return;
    }

    addProvider(newProvider);
    setNewProvider({
      providerName: "",
      providerRFC: "",
      providerAdress: "",
      providerTel: "",
      providerEmail: "",
    });
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "10%" }}>
        <Row>
          <Col>
            <ListGroup variant="flush">
              {proveedores.map((provider) => (
                <ListGroup.Item as="ol" key={provider.providerID}>
                  <Row style={{ width: "100%" }}>
                    <Card>
                      <Card.Body>
                        <Card.Title>{provider.providerName}</Card.Title>
                      </Card.Body>
                      <Card.Footer>
                        <small className="text-muted">
                          {provider.providerRFC}
                          <br />
                          {provider.providerAdress}
                          <br />
                          {provider.providerTel}
                          <br />
                          {provider.providerEmail}
                        </small>
                      </Card.Footer>
                    </Card>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nuevo Proveedor</Form.Label>
                    <Form.Control
                      type="text"
                      id="providerName"
                      name="providerName"
                      value={providerName}
                      placeholder="Nombre del proveedor"
                      onChange={onChangeFields}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>RFC</Form.Label>
                    <Form.Control
                      type="text"
                      id="providerRFC"
                      name="providerRFC"
                      value={providerRFC}
                      placeholder="RFC del proveedor"
                      onChange={onChangeFields}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Direccion</Form.Label>
                    <Form.Control
                      type="text"
                      id="providerAdress"
                      name="providerAdress"
                      value={providerAdress}
                      placeholder="Calle, numero, cp ..."
                      onChange={onChangeFields}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="text"
                      id="providerTel"
                      name="providerTel"
                      value={providerTel}
                      placeholder="000-000-00-00"
                      onChange={onChangeFields}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control
                      type="text"
                      id="providerEmail"
                      name="providerEmail"
                      value={providerEmail}
                      placeholder="correo@ejemplo.com"
                      onChange={onChangeFields}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <br />
              </Row>
              <Row>
                <Col style={{ margin: "0 auto" }}>
                  <Button
                    style={{ width: "100px" }}
                    type="submit"
                    onClick={onSubmitProveedor}
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Proveedores;
