import React, { useContext, useState, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import UsuarioContext from "../context/usuarios/usuarioContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ReactInputDateMask from "react-input-date-mask";

import Select from "react-select";

import "bootstrap/dist/css/bootstrap.css";

const NuevoUsuario = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  const typeContract = [
    { value: "Base", label: "Base" },
    { value: "Comisión", label: "Comisión" },
  ];

  const grades = [
    { value: "Secundaria", label: "Secundaria" },
    { value: "Bachillerato", label: "Bachillerato" },
    { value: "Técnico", label: "Técnico" },
    { value: "Licenciatura", label: "Licenciatura" },
    { value: "Escpecialidad", label: "Escpecialidad" },
    { value: "Maestría", label: "Maestría" },
    { value: "Doctorado", label: "Doctorado" },
    { value: "Postoctorado", label: "Postoctorado" },
  ];

  const curpPattern = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

  const usuarioContext = useContext(UsuarioContext);
  const {
    message,
    allJobs,
    registerNewWorker,
    getJobs,
    addJob,
  } = usuarioContext;

  useEffect(() => {
    authenticatedUser();
    getJobs();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para usuarios
  const [usuarioNuevo, setUsuarioNuevo] = useState({
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombre: "",
    curp: "",
    rfc: "",
    telefono: "",
    correo: "",
    puesto: "",
    fechaIngreso: "",
    gradoEstudios: "",
    numSeguro: "",
    contrato: "",
    horasSemana: "",
    archivoEstudios: "",
    cedula: "",
    clavePresupuestal: "",
    inputModal: "",
    showModal: false,
    foto: "",
  });

  //extraer del pago
  const {
    apellidoPaterno,
    apellidoMaterno,
    nombre,
    curp,
    rfc,
    telefono,
    correo,
    puesto,
    fechaIngreso,
    gradoEstudios,
    numSeguro,
    contrato,
    horasSemana,
    archivoEstudios,
    clavePresupuestal,
    cedula,
    inputModal,
    showModal,
    foto,
  } = usuarioNuevo;

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let newErrors = {};

    if (apellidoPaterno.trim() === "") {
      newErrors.apPat = "Llena todos los campos necesarios";
    }
    if (apellidoMaterno.trim() === "") {
      newErrors.apMat = "Llena todos los campos necesarios";
    }

    if (nombre.trim() === "") {
      newErrors.nom = "Llena todos los campos necesarios";
    }

    if (curp.trim() === "") {
      newErrors.curp = "Llena todos los campos necesarios";
    }

    if (rfc.trim() === "") {
      newErrors.rfc = "Llena todos los campos necesarios";
    }

    if (telefono.trim() === "") {
      newErrors.tel = "Llena todos los campos necesarios";
    }

    if (correo.trim() === "") {
      newErrors.correo = "Llena todos los campos necesarios";
    }

    if (puesto === "") {
      newErrors.puesto = "Llena todos los campos necesarios";
    }

    if (fechaIngreso.trim() === "") {
      newErrors.ingreso = "Llena todos los campos necesarios";
    }

    if (gradoEstudios === "") {
      newErrors.estudios = "Llena todos los campos necesarios";
    }

    if (contrato === "") {
      newErrors.contrato = "Llena todos los campos necesarios";
    }

    if (horasSemana.trim() === "") {
      newErrors.horas = "Llena todos los campos necesarios";
    }

    if (!curp.match(curpPattern)) {
      newErrors.curp = "Formato invalido";
    }

    if (!curp.match(curpPattern)) {
      newErrors.curp = "Formato invalido";
    }

    return newErrors;
  };

  const onChangeFields = (e) => {
    if (e.target.name === "horasSemana" || e.target.name === "telefono") {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    setUsuarioNuevo({
      ...usuarioNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDocument = (e) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      archivoEstudios: e.target.files[0],
    });

    console.log(archivoEstudios);
  };

  const onChangeSelectPuesto = (option) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      puesto: option,
    });
  };

  const onChangeSelectContrato = (option) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      contrato: option,
    });
  };

  const onChangeSelectGrado = (option) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      gradoEstudios: option,
    });
  };

  const onChangeFecha = (fecha) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      fechaIngreso: fecha,
    });
  };

  const onSubmitUser = (e) => {
    e.preventDefault();

    const err = validateFields();

    if (Object.keys(err).length > 0) {
      setErrors(err);

      showAlert(err[Object.keys(err)[0]], "Error", "danger");
    } else {
      // let usuario;
      // if (user) {
      //   usuario = user.rfc;
      // } else {
      //   return;
      // }
      if (!user) {
        return;
      }

      let data = new FormData();

      data.append("apellidoPat", apellidoPaterno);
      data.append("apellidoMat", apellidoMaterno);
      data.append("nombre", nombre);
      data.append("curp", curp);
      data.append("rfc", rfc);
      data.append("telefono", telefono);
      data.append("correo", correo);
      data.append("puestoLabel", puesto.label);
      data.append("puestoValue", puesto.value);
      data.append("fechaIngreso", fechaIngreso);
      data.append("gradoEstudios", gradoEstudios.value);
      data.append("numSeguro", numSeguro);
      data.append("contrato", contrato.value);
      data.append("horasSemana", horasSemana);
      data.append("cedula", cedula);
      data.append("clavePresupuestal", clavePresupuestal);
      data.append("file", archivoEstudios);
      data.append("photo", foto);

      registerNewWorker(data);

      setUsuarioNuevo({
        apellidoPaterno: "",
        apellidoMaterno: "",
        nombre: "",
        curp: "",
        rfc: "",
        telefono: "",
        correo: "",
        puesto: "",
        fechaIngreso: "",
        gradoEstudios: "",
        numSeguro: "",
        contrato: "",
        horasSemana: "",
        archivoEstudios: "",
        clavePresupuestal: "",
        cedula: "",
        inputModal: "",
        showModal: false,
      });
    }
  };

  const onShowModal = (e) => {
    if (e.target.name === "nuevoPuestoBtn") {
      setUsuarioNuevo({
        ...usuarioNuevo,
        isPuesto: true,
        showModal: true,
      });
    } else {
      setUsuarioNuevo({
        ...usuarioNuevo,
        isPuesto: false,
        showModal: true,
      });
    }
  };

  const onSubmitModal = (e) => {
    e.preventDefault();
    if (inputModal.trim() === "") {
      showAlert("Llena el campo", "Error", "danger");
      return;
    }
    if (e.target.name === "submitNuevoPuesto") {
      addJob(inputModal);
    }

    setUsuarioNuevo({
      ...usuarioNuevo,
      showModal: false,
      inputModal: "",
    });
  };

  const onHideModal = (e) => {
    setUsuarioNuevo({
      ...usuarioNuevo,
      showModal: false,
    });
  };

  const controlStylesHasError = (hasError) =>
    hasError ? { borderColor: "red" } : {};

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "10%" }}>
        <Form>
          <Row className="text-center">
            <Col xs={4} md={12}>
              <h5> Datos Personales </h5>
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="apellidoPaterno"
                name="apellidoPaterno"
                value={apellidoPaterno}
                isInvalid={!!errors.apPat}
                placeholder="Apellido Paterno"
                onChange={onChangeFields}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                id="apellidoMaterno"
                name="apellidoMaterno"
                value={apellidoMaterno}
                isInvalid={!!errors.apMat}
                placeholder="Apellido Materno"
                onChange={onChangeFields}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                id="nombre"
                name="nombre"
                value={nombre}
                isInvalid={!!errors.nom}
                placeholder="Nombre(s)"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="curp"
                name="curp"
                value={curp}
                isInvalid={!!errors.curp}
                placeholder="CURP"
                onChange={onChangeFields}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                id="rfc"
                name="rfc"
                value={rfc}
                isInvalid={!!errors.rfc}
                placeholder="RFC"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="telefono"
                name="telefono"
                value={telefono}
                isInvalid={!!errors.tel}
                placeholder="telefono"
                onChange={onChangeFields}
              />
            </Col>
            <Col>
              <Form.Control
                type="email"
                id="correo"
                name="correo"
                value={correo}
                isInvalid={!!errors.correo}
                placeholder="Correo Electronico"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row className="text-center">
            <Col xs={4} md={12}>
              <h5> Información laboral </h5>
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Select
                id="puesto"
                name="puesto"
                onChange={onChangeSelectPuesto}
                placeholder="Cargo"
                isSearchable={true}
                value={puesto}
                // isInvalid={!!errors.puesto}
                options={allJobs.map((job) => ({
                  value: job.id,
                  label: job.cargo,
                }))}
                styles={{
                  control: (base) => ({
                    ...base,
                    ...controlStylesHasError(!!errors.puesto),
                  }),
                }}
              />
            </Col>
            <Col xs="auto">
              <Button
                name="nuevoPuestoBtn"
                variant="outline-primary"
                onClick={onShowModal}
              >
                Agregar Cargo
              </Button>
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col xs="auto">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={!!errors.ingreso ? { borderColor: "red" } : {}}
                  >
                    Fecha de ingreso
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <ReactInputDateMask
                  id="fechaIngreso"
                  name="fechaIngreso"
                  mask="dd/mm/yyyy"
                  //showMaskOnFocus={true}
                  className="form-control"
                  value={fechaIngreso}
                  // isInvalid={!!errors.ingreso}
                  //showMaskOnHover={true}
                  onChange={onChangeFecha}
                />
              </InputGroup>
            </Col>
            <Col xs="3">
              <Select
                id="contrato"
                name="contrato"
                onChange={onChangeSelectContrato}
                placeholder="Tipo de contrato"
                value={contrato}
                // isInvalid={!!errors.contrato}
                options={typeContract}
                styles={{
                  control: (base) => ({
                    ...base,
                    ...controlStylesHasError(!!errors.contrato),
                  }),
                }}
              />
            </Col>
            <Col xs="3">
              <Form.Control
                type="horasSemana"
                id="horasSemana"
                name="horasSemana"
                value={horasSemana}
                isInvalid={!!errors.horas}
                placeholder="Horas por Semana"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="numSeguro"
                name="numSeguro"
                value={numSeguro}
                placeholder="Numero de Seguro"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Select
                id="gradoEstudios"
                name="gradoEstudios"
                onChange={onChangeSelectGrado}
                placeholder="Último grado de estudios"
                value={gradoEstudios}
                // isInvalid={!!errors.estudios}
                options={grades}
                styles={{
                  control: (base) => ({
                    ...base,
                    ...controlStylesHasError(!!errors.estudios),
                  }),
                }}
              />
            </Col>
            <Col>
              <Form.Control
                type="file"
                id="archivoEstudios"
                name="archivoEstudios"
                onChange={onChangeDocument}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="cedula"
                name="cedula"
                value={cedula}
                placeholder="Cedula Profesional"
                onChange={onChangeFields}
              />
            </Col>
          </Row>
          <Row>
            <br />
          </Row>

          <Row>
            <Col>
              <Form.Control
                type="text"
                id="clavePresupuestal"
                name="clavePresupuestal"
                value={clavePresupuestal}
                placeholder="Clave presupuestal"
                onChange={onChangeFields}
              />
            </Col>
          </Row>

          <Row>
            <br />
          </Row>

          <Row>
            <Col style={{ left: "70vw" }}>
              <Button
                style={{ width: "100px" }}
                type="submit"
                onClick={onSubmitUser}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <Modal
          show={showModal}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="sm"
        >
          <Modal.Header closeButton={true} onHide={onHideModal}>
            <Modal.Title>Nuevo Cargo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    id="inputModal"
                    name="inputModal"
                    value={inputModal}
                    placeholder="Nombre"
                    onChange={onChangeFields}
                  />
                </Col>
              </Row>
              <Row>
                <br />
              </Row>
              <Row>
                <Col>
                  <Button
                    // style={{ width: "100px" }}
                    type="submit"
                    name="submitNuevoPuesto"
                    onClick={onSubmitModal}
                    block
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHideModal} variant="outline-danger">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default NuevoUsuario;
