export const columnsTotal = [
  {
    name: "Subtotal",
    selector: "subtotal",
    sortable: "true",
    //width: "70px",
    center: "true",
  },
  {
    name: "Cancelado",
    selector: "cancelado",
    sortable: "true",
    center: "true",
  },
  {
    name: "TOTAL",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];

export const columnsTotalCareers = [
  {
    name: "Clave",
    selector: "clave",
    sortable: "true",
    center: "true",
  },
  {
    name: "Programa",
    selector: "nombre",
    sortable: "true",
    center: "true",
  },
  {
    name: "Total",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];

export const columnsTotalConcepts = [
  {
    name: "Código",
    selector: "codigo",
    sortable: "true",
    center: "true",
  },
  {
    name: "Descripción",
    selector: "nombre",
    sortable: "true",
    center: "true",
  },
  {
    name: "Total",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];

export const columnsReportPayments = [
  {
    name: "Folio",
    selector: "folio",
    sortable: "true",
    center: "true",
  },
  {
    name: "Alumno",
    selector: "nombreAlumno",
    sortable: "true",
    center: "true",
  },
  {
    name: "Fecha",
    selector: "fecha_pago",
    sortable: "true",
    center: "true",
  },
  {
    name: "Estado",
    selector: "estado",
    sortable: "true",
    center: "true",
  },
  {
    name: "cantidad",
    selector: "cantidad_pagada",
    sortable: "true",
    center: "true",
  },
  {
    name: "Concepto",
    selector: "codigo_concepto",
    sortable: "true",
    center: "true",
  },
];

export const conditionalRowStyles = [
  {
    when: (row) => row.cancelado === true,
    style: {
      //backgroundColor: "#FCA3A3",
      color: "#BD0202",
      // "&:hover": {
      //   cursor: "not-allowed",
      // },
    },
  },
];
