import React, { useState, useEffect, useContext } from "react";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import AlertContext from "../../context/alerts/alertContext";
import AuthContext from "../../context/auth/authContext";

const PwdChange = (props) => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { message, authenticated, user, authenticatedUser, changePwd } =
    authContext;

  const [change, setChange] = useState({
    password: "",
    passwordConfirm: "",
  });

  //extraer de usuario
  const { password, passwordConfirm } = change;

  useEffect(() => {
    authenticatedUser();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, props.history]);

  const onChangeFields = (e) => {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitPwd = (e) => {
    e.preventDefault();
    if (password.trim() === "" || passwordConfirm.trim() === "") {
      showAlert("Llena todos los campos", "Campos necesarios", "danger");
      return;
    }
    if (password !== passwordConfirm) {
      showAlert("Las contraseñas no coinciden", "Error", "danger");
      return;
    }
    if (password.length < 8) {
      showAlert(
        "La contraseña debe contener al menos 8 caracteres",
        "Error",
        "danger"
      );
      return;
    }
    if (authenticated) {
      changePwd({ rfc: user.rfc, contraseña: password });
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundImage:
          "linear-gradient(to bottom right, #84C1F7, #005CAB, #011B33)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundColor: "#005CAB",
      }}
    >
      {authenticated ? (
        <div
          style={{
            paddingTop: "25vh",
            width: "20%",
            minWidth: "270px",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "0.9",
          }}
        >
          <div
            style={{
              width: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1
              style={{
                fontSize: "24pt",
                color: "white",
                textAlign: "center",
              }}
            >
              ¿Es la primera vez que accedes?
            </h1>
          </div>
          <Card>
            <div className="form-title">
              <br />
              Nueva contraseña
            </div>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Control
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Nueva contraseña"
                    onChange={onChangeFields}
                    className="login-input"
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Control
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    placeholder="Confirma la contraseña"
                    onChange={onChangeFields}
                    className="login-input"
                  />
                </Form.Group>

                <br />
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={onSubmitPwd}
                  block
                >
                  Cambiar
                </Button>
              </Form>
            </Card.Body>
            <Card.Footer>
              {alerta ? (
                <Alert key={alerta.category} variant={alerta.category}>
                  <Alert.Heading>{alerta.title}</Alert.Heading>
                  {alerta.msg}
                </Alert>
              ) : null}
            </Card.Footer>
            <br />
          </Card>
        </div>
      ) : (
        <div
          style={{
            width: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h1
            style={{
              fontSize: "50pt",
              color: "white",
            }}
          >
            404
          </h1>
        </div>
      )}
    </div>
  );
};

export default PwdChange;
