import React, { Fragment, useContext, useEffect } from "react";
import { Switch } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

import PrivateRoute from "../route/PrivateRoute";

import PagoState from "./context/pagos/pagoState";
import ConceptoState from "./context/conceptos/conceptoState";

import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

import NuevoPago from "./components/NuevoPago";
import NuevoConcepto from "./components/NuevoConcepto";
import BuscarPagos from "./components/BuscarPagos";
import CancelarPago from "./components/CancelarPago";
import Cortes from "./components/Cortes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import EqualizerTwoToneIcon from "@material-ui/icons/EqualizerTwoTone";
import BlockTwoTone from "@material-ui/icons/BlockTwoTone";

import { roles } from "../../config/constants";

const MainRecibos = () => {
  const menuOptions = [
    {
      opKey: "buscar",
      opLabel: "Buscar",
      opPath: "/recibos/buscar",
      opIcon: <SearchTwoToneIcon />,
    },
    {
      opKey: "nuevoPago",
      opLabel: "Nuevo Pago",
      opPath: "/recibos/nuevo-pago",
      opIcon: <AddTwoToneIcon />,
    },
    {
      opKey: "corte",
      opLabel: "Corte",
      opPath: "/recibos/cortes",
      opIcon: <EqualizerTwoToneIcon />,
    },
  ];
  const bossOptions = [
    {
      opKey: "conceptos",
      opLabel: "Conceptos",
      opPath: "/recibos/conceptos-pago",
      opIcon: <AddTwoToneIcon />,
    },
    {
      opKey: "cancelarPago",
      opLabel: "Cancelar Pago",
      opPath: "/recibos/cancelar-pago",
      opIcon: <BlockTwoTone />,
    },
  ];
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { rol, user, authenticatedUser } = authContext;

  useEffect(() => {
    authenticatedUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <SideBar
              menuOptions={
                rol === 7 ? [...menuOptions, ...bossOptions] : menuOptions
              }
            />
          </Col>
          <Col xs={10}>
            <Header user={user} />
            <div>
              <main>
                <PagoState>
                  <ConceptoState>
                    <Switch>
                      <PrivateRoute
                        path="/recibos/buscar"
                        component={BuscarPagos}
                        roles={[
                          roles.JefeAdministrativo,
                          roles.CoordinadorAdministrativo,
                        ]}
                      />
                      <PrivateRoute
                        path="/recibos/nuevo-pago"
                        component={NuevoPago}
                        roles={[
                          roles.JefeAdministrativo,
                          roles.CoordinadorAdministrativo,
                        ]}
                      />
                      <PrivateRoute
                        path="/recibos/cortes"
                        component={Cortes}
                        roles={[
                          roles.JefeAdministrativo,
                          roles.CoordinadorAdministrativo,
                        ]}
                      />
                      <PrivateRoute
                        path="/recibos/conceptos-pago"
                        component={NuevoConcepto}
                        roles={[roles.JefeAdministrativo]}
                      />
                      <PrivateRoute
                        path="/recibos/cancelar-pago"
                        component={CancelarPago}
                        roles={[roles.JefeAdministrativo]}
                      />
                    </Switch>
                  </ConceptoState>
                </PagoState>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MainRecibos;
