import React, { useContext } from "react";

import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import ItemsContext from "../context/itemsContext";

const ExpandedActivity = ({ data }) => {
  const itemsContext = useContext(ItemsContext);

  const { getInvoiceFilePDF } = itemsContext;

  return (
    <Row>
      <Col>
        <ListGroup flush>
          <ListGroup.Item
            as="ul"
            style={{ fontSize: "11pt", fontWeight: "bold" }}
          >
            <Row style={{ width: "100%" }}>
              <Col xs={8}>Articulos</Col>
              <Col xs={2}>Cantidad</Col>
            </Row>
          </ListGroup.Item>
          {data.articulos.map((item) => (
            <ListGroup.Item as="li" style={{ fontSize: "11pt" }}>
              <Row>
                <Col xs={8}>{item.articulo}</Col>
                <Col xs={2}>{item.cantidad}</Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
      <Col xs="auto">
        {data.factura ? (
          <Row style={{ height: "100%", marginBottom: "0" }}>
            <Col>
              <Button
                variant="secondary"
                on
                onClick={() => getInvoiceFilePDF(data.folio)}
              >
                Ver Factura
              </Button>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default ExpandedActivity;
