import React from "react";

import { useHistory } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "bootstrap/dist/css/bootstrap.css";

const SideBar = ({ menuOptions }) => {
  const { push } = useHistory();

  return (
    <Navbar
      collapseOnSelect
      style={{ minHeight: "100hv", width: "100%" }}
      expand="lg"
      variant="light"
    >
      <Navbar.Brand href="#"></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto flex-column sidebar">
          <div style={{ paddingTop: "8vh" }}></div>
          {menuOptions.map((option) => (
            <Nav.Item>
              <Nav.Link
                key={option.opKey}
                onClick={() => {
                  push(option.opPath);
                }}
              >
                <Row>
                  <Col>{option.opIcon}</Col>
                  <Col>{option.opLabel}</Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default SideBar;
