import React, { useState, useContext, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import AssignmentContext from "../context/assignmentContext";

import Toast from "react-bootstrap/Toast";

const AsignacionesCAE = () => {
  const alertContext = useContext(AlertContext);
  const { showAlert, alerta } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser } = authContext;

  const assignmentContext = useContext(AssignmentContext);

  const {
    message,
    filteredStudents,
    getStudentsList,
    filterStudents,
    students,
  } = assignmentContext;

  useEffect(() => {
    getStudentsList();
    authenticatedUser();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para usuarios
  const [asignacion, setAsignacion] = useState({
    filtroestudiante: "",
    estudiante: "",
    motivo: "",
    item: "",
    descripcion: "",
    tipoAsignacion: { value: "medicamento", label: "Medicamento" },
    itemLabel: ["Medicamento", "Descripción del medicamento"],
    motivoLabel: "Razon por la que se proveyó el medicamento...",
    descriptionAbilited: false,
  });

  //extraer del usuario
  const {
    motivo,
    item,
    descripcion,
    filtroestudiante,
    estudiante,
    tipoAsignacion,
    itemLabel,
    motivoLabel,
    descriptionAbilited,
  } = asignacion;

  const onChangeFields = (e) => {
    setAsignacion({
      ...asignacion,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "filtroestudiante") {
      filterStudents(e.target.value, students);
    }
  };

  const onChangeSelectAlumno = (option) => {
    setAsignacion({
      ...asignacion,
      estudiante: option,
    });
  };

  const onChangeSelectTipo = (option) => {
    let text = ["Medicamento", "Descripción del medicamento"];
    let descFlag = false;
    let motivoText = "Razon por la que se proveyó el medicamento...";

    if (option.value === "prestamo") {
      text = ["Objeto(s)", "Tipo de objeto u objetos"];
      descFlag = true;
      motivoText = "Razón por la que se hace el prestamo...";
    }
    setAsignacion({
      ...asignacion,
      tipoAsignacion: option,
      itemLabel: text,
      descriptionAbilited: descFlag,
      motivoLabel: motivoText,
      item: "",
      motivo: "",
      descripcion: "",
    });
  };

  const onSubmitAssignment = (e) => {
    e.preventDefault();

    // if (
    //   apellidoPaterno.trim() === "" ||
    //   apellidoMaterno.trim() === "" ||
    //   nombre.trim() === "" ||
    //   curp.trim() === "" ||
    //   rfc.trim() === "" ||
    //   correo.trim() === "" ||
    //   puesto === ""
    // ) {
    //   showAlert("Llena todos los campos", "Error", "danger");
    //   return;
    // }

    // if (!curp.match(curpPattern)) {
    //   showAlert("El formato CURP es invalido", "Error", "danger");
    //   return;
    // }
    // addUser({
    //   rfc,
    //   curp,
    //   puesto,
    //   apellidoPaterno,
    //   apellidoMaterno,
    //   nombre,
    //   correo,
    // });
    // changeStateModalUser(false);
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "Exito"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <Form>
        <Row>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>Búsqueda</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  id="filtroEstudiante"
                  name="filtroestudiante"
                  value={filtroestudiante}
                  placeholder="Busqueda"
                  onChange={onChangeFields}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Alumno</Form.Label>
              <Col mb={10}>
                <Select
                  id="estudiante"
                  name="estudiante"
                  onChange={onChangeSelectAlumno}
                  value={estudiante}
                  options={filteredStudents.map((student) => ({
                    value: student.matricula,
                    label: student.nombre,
                  }))}
                  placeholder="Selecciona"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Tipo</Form.Label>
          <Select
            id="tipoAsignacion"
            name="tipoAsignacion"
            onChange={onChangeSelectTipo}
            value={tipoAsignacion}
            options={[
              { value: "medicamento", label: "Medicamento" },
              { value: "prestamo", label: "Prestamo" },
            ]}
            placeholder="Selecciona"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{itemLabel[0]}</Form.Label>
          <Col>
            <Form.Control
              type="text"
              id="item"
              name="item"
              value={item}
              placeholder={itemLabel[1]}
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        {descriptionAbilited ? (
          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                id="descripcion"
                name="descripcion"
                value={descripcion}
                placeholder="Descripción del objeto(s)"
                onChange={onChangeFields}
              />
            </Col>
          </Form.Group>
        ) : null}

        <Form.Group>
          <Form.Label>Motivo</Form.Label>
          <Col>
            <Form.Control
              as="textarea"
              id="motivo"
              name="motivo"
              value={motivo}
              placeholder={motivoLabel}
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>

        <Row>
          <br />
        </Row>
        <Row>
          <Col xs={{ span: 3, offset: 4 }}>
            <Button
              // style={{ width: "100px"}}
              type="submit"
              onClick={onSubmitAssignment}
              block
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default AsignacionesCAE;
