import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  GET_USER,
  CLOSE_SESSION,
  CHANGE_PWD,
  UNSET_MESSAGE,
} from "../../types";
import jsonwebtoken from "jsonwebtoken";

const authReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_PWD:
    case CLOSE_SESSION:
    case LOGIN_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        rol: null,
        authenticated: null,
        message: action.payload,
        loading: false,
        firstFlag: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      let rol;
      let first;
      try {
        let decoded = jsonwebtoken.decode(action.payload.token);
        //console.log(decoded);
        rol = decoded.sub[1];
        first = decoded.sub[2];
      } catch (error) {
        //console.log(error);
      }
      return {
        ...state,
        authenticated: true,
        message: null,
        rol: rol,
        loading: false,
        firstFlag: first,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        rol: action.payload.rol,
        loading: false,
      };
    case UNSET_MESSAGE:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default authReducer;
