import React, { useContext } from "react";

import UsuarioContext from "../context/usuarios/usuarioContext";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const ExpandedPayment = ({ data }) => {
  const usuarioContext = useContext(UsuarioContext);
  const { changeStatusWorker } = usuarioContext;

  const onChangeStatusWorker = (e) => {
    e.preventDefault();
    changeStatusWorker(e.target.name);
  };

  return (
    <div style={{ paddingLeft: "50px" }}>
      {data ? (
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            <b>CURP :</b> {data.curp} <br />
            <b>Telefono :</b> {data.tel} <br />
            {data.ingreso ? (
              <>
                <b>Ingreso en:</b> {data.ingreso}
              </>
            ) : null}{" "}
            <br />
            {data.laborando ? (
              <>
                <b>Tiempo laborando:</b> {data.laborando.años} años y{" "}
                {data.laborando.dias} días
              </>
            ) : null}
            <br />
            {data.contrato ? (
              <>
                <b>Tipo de contrato:</b> {data.contrato}
              </>
            ) : null}{" "}
            <br />
            <b>Ultimo grado de estudios:</b> {data.estudios} <br />
            <b>Horas de trabajo a la semana:</b> {data.horasDeTrabajo} <br />
            {data.cedula ? (
              <>
                <b>Cedula profesional:</b> {data.cedula}
              </>
            ) : null}
            <br />
            {data.clave_plaza ? (
              <>
                <b>Clave de plaza:</b> {data.clave_plaza}
              </>
            ) : null}
            <br />
            {data.categoria_plaza ? (
              <>
                <b>Categoria:</b> {data.categoria_plaza}
              </>
            ) : null}
            <br />
            {data.fecha_inactividad ? (
              <>
                <b>Inactivo desde:</b> {data.fecha_inactividad}
              </>
            ) : null}
            <br />
          </Card.Body>
          {data.rol !== 0 ? (
            <Card.Footer>
              <Button
                variant={data.activo ? "outline-danger" : "outline-dark"}
                name={data.rfc}
                onClick={onChangeStatusWorker}
              >
                {data.activo ? "Desactivar" : "Activar"}
              </Button>
              <br />
            </Card.Footer>
          ) : null}
        </Card>
      ) : null}
    </div>
  );
};

export default ExpandedPayment;
