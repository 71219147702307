import React, { useContext, useEffect, useState } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import PagoContext from "../context/pagos/pagoContext";

import print from "print-js";

import ExpandedPayment from "./ExpandedPayment";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import "bootstrap/dist/css/bootstrap.css";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const conditionalRowStyles = [
  {
    when: (row) => row.cancelado === true,
    style: {
      //backgroundColor: "#FCA3A3",
      color: "#BD0202",
      // "&:hover": {
      //   cursor: "not-allowed",
      // },
    },
  },
];

const BuscarPagos = () => {
  const alertContext = useContext(AlertContext);
  const { alerta } = alertContext;

  const pagoContext = useContext(PagoContext);
  const { recibo, paymentsList, getPaymentsList, getRecibo, cleanReceipt } =
    pagoContext;

  useEffect(() => {
    getPaymentsList("initial-search");
    //console.log(recibo);
    if (recibo) {
      //Build a URL from the file
      const fileURL = URL.createObjectURL(recibo);
      //Open the URL on new Window
      //window.open(fileURL);
      print(fileURL);
      //print({ printable: recibo, type: "pdf", base64: true });
      cleanReceipt();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recibo]);

  const [filtro, setFiltro] = useState({
    pattern: "",
  });

  const { pattern } = filtro;

  const onChangeSearch = (e) => {
    setFiltro({
      ...filtro,
      pattern: e.target.value,
    });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (pattern.trim() === "") {
      getPaymentsList("initial-search");
    } else {
      getPaymentsList(pattern);
    }

    setFiltro({
      ...filtro,
      pattern: "",
    });
  };

  const handlePrint = (e) => {
    getRecibo(e.target.name);
  };

  const columns = [
    {
      name: "#Folio",
      selector: "folio",
      sortable: "true",
      width: "100px",
      center: "true",
    },
    {
      name: "Alumno",
      selector: "alumno",
      sortable: "true",
      grow: 3,
    },
    {
      name: "Concepto",
      selector: "codigo_concepto",
      sortable: "true",
      center: "true",
    },
    {
      name: "Cantidad",
      selector: "cantidad_pagada",
      sortable: "true",
      center: "true",
    },
    {
      name: "Fecha",
      selector: "fecha_pago",
      sortable: "true",
      center: "true",
    },
    {
      name: "Elaboró",
      selector: "usuario_nombre",
      sortable: "true",
      center: "true",
      grow: 2,
    },
    {
      name: "-",
      button: true,
      width: "83px",
      cell: (row) => {
        //console.log(row);
        return (
          <Button
            size="sm"
            variant="link"
            name={row.folio}
            onClick={handlePrint}
            data-tag="allowRowEvents"
            block
          >
            Imprimir
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "Exito"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "10%",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "350px",
        }}
      >
        <Form>
          <Row>
            <Col>
              <Form.Control
                type="text"
                id="concepto"
                name="concepto"
                value={pattern}
                placeholder="Buscar"
                onChange={onChangeSearch}
              />
            </Col>
            <Col>
              <Button type="submit" onClick={onSubmitSearch}>
                <SearchTwoToneIcon />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ paddingTop: "50px" }}>
        <DataTable
          columns={columns}
          keyField="folio"
          pagination
          data={paymentsList}
          striped={true}
          responsive={true}
          theme="blue-gray"
          conditionalRowStyles={conditionalRowStyles}
          expandableRows={true}
          // expandOnRowClicked={true}
          // expandableRowsHideExpander={true}
          expandableRowsComponent={<ExpandedPayment />}
        />
      </div>
    </div>
  );
};

export default BuscarPagos;
