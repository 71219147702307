import React, { useContext, useEffect, useState } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import ItemsContext from "../context/itemsContext";

import print from "print-js";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";

import ExpandedActivity from "./ExpandedActivity";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import "bootstrap/dist/css/bootstrap.css";

import DataTable, { createTheme } from "react-data-table-component";

createTheme("blue-gray", {
  text: {
    primary: "#000000",
    secondary: "#0F0F0F",
  },
  background: {
    default: "#FFFFFF",
  },
  striped: {
    default: "#CACED6",
    text: "#0F0F0F",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#6E7B97",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const conditionalRowStyles = [
  {
    when: (row) => row.cantidad === 0,
    style: {
      //backgroundColor: "#FCA3A3",
      color: "#BD0202",
      // "&:hover": {
      //   cursor: "not-allowed",
      // },
    },
  },
];

const Actividad = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const itemsContext = useContext(ItemsContext);
  const { activityList, message, invoiceFilePDF, getActivityReport } =
    itemsContext;

  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getTime() - 24 * 60 * 60 * 1000 * 30
  );

  useEffect(() => {
    getActivityReport({
      init_date: getStringDate(oneMonthAgo),
      end_date: getStringDate(currentDate),
    });
    if (invoiceFilePDF) {
      const fileURL = URL.createObjectURL(invoiceFilePDF);
      window.open(fileURL);
    }
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state las fechinis
  const [activityInterval, setActivityInterval] = useState({
    initDate: currentDate,
    endDate: currentDate,
  });

  //extraer fechas
  const { initDate, endDate } = activityInterval;

  const getStringDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let newDate = "";

    if (month < 10) {
      newDate = `${day}/0${month}/${year}`;
    } else {
      newDate = `${day}/${month}/${year}`;
    }

    return newDate;
  };

  const onChangeInitDate = (date) => {
    setActivityInterval({
      ...activityInterval,
      initDate: date,
    });
  };

  const onChangeEndDate = (date) => {
    setActivityInterval({
      ...activityInterval,
      endDate: date,
    });
  };

  const onSubmitSearch = (e) => {
    if (endDate < initDate) {
      showAlert("No es un intervalo válido de fechas", "Error! ", "danger");
      return;
    }
    let today = new Date();
    if (endDate > today || initDate > today) {
      showAlert(
        "No es un intervalo válido, las fechas no pueden ser mayores a hoy",
        "Error! ",
        "danger"
      );
      return;
    }

    getActivityReport({
      init_date: getStringDate(initDate),
      end_date: getStringDate(endDate),
    });
  };

  const columns = [
    {
      name: "# Folio",
      selector: "folio",
      sortable: "true",
      width: "100px",
      center: "true",
    },
    {
      name: "Tipo",
      selector: "tipo",
      sortable: "true",
    },
    {
      name: "Fecha",
      selector: "fecha",
      sortable: "true",
      center: "true",
    },
    {
      name: "Realizó",
      selector: "usuario",
      sortable: "true",
      center: "true",
      grow: 3,
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "Exito"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{ paddingTop: "10%", marginRight: "auto", marginLeft: "auto" }}
      >
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text>Fecha Inicio</InputGroup.Text>
              <DatePicker
                selected={initDate}
                onChange={onChangeInitDate}
                closeOnScroll={true}
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text>Fecha Fin</InputGroup.Text>
              <DatePicker
                selected={endDate}
                onChange={onChangeEndDate}
                closeOnScroll={true}
                dateFormat="dd/MM/yyyy"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <Button onClick={onSubmitSearch}>
                <SearchTwoToneIcon />
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <br />
        </Row>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <DataTable
          columns={columns}
          keyField="folio"
          pagination
          data={activityList}
          striped={true}
          responsive={true}
          theme="blue-gray"
          conditionalRowStyles={conditionalRowStyles}
          expandableRows={true}
          expandOnRowClicked={true}
          expandableRowsHideExpander={true}
          expandableRowsComponent={<ExpandedActivity />}
        />
      </div>
    </div>
  );
};

export default Actividad;
