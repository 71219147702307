import React, { useState, useContext, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import ItemsContext from "../context/itemsContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import "bootstrap/dist/css/bootstrap.css";

const CategoriasItems = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const itemsContext = useContext(ItemsContext);
  const { message, categorias, getAllCategories, saveNewCategory } =
    itemsContext;

  useEffect(() => {
    getAllCategories();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para iniciar sesión
  const [newCategory, setNewCategory] = useState("");

  const onSubmitCategory = (e) => {
    e.preventDefault();
    if (newCategory.trim() === "") {
      showAlert("Escribe una categoria!", "Error", "danger");
      return;
    }

    saveNewCategory(newCategory);
    setNewCategory("");
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "10%" }}>
        <Row>
          <Col>
            <ListGroup variant="flush">
              {categorias.map((categoria) => (
                <ListGroup.Item as="ol">
                  <Row style={{ width: "100%" }}>
                    <Col>{categoria.categoria}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nueva Categoria</Form.Label>
                    <Form.Control
                      type="text"
                      id="newCategory"
                      name="newCategory"
                      value={newCategory}
                      placeholder="Nombre de la nueva categoria..."
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <br />
              </Row>
              <Row>
                <Col style={{ margin: "0 auto" }}>
                  <Button
                    style={{ width: "100px" }}
                    type="submit"
                    onClick={onSubmitCategory}
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CategoriasItems;
