import React, { Fragment, useContext, useEffect } from "react";
import { Switch } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

import PrivateRoute from "../route/PrivateRoute";
import { roles } from "../../config/constants";

import ItemsState from "./context/itemsState";
import UsuarioState from "../rh/context/usuarios/usuarioState";

import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

import Existentes from "./components/Existentes";
import NuevaEntrada from "./components/NuevaEntrada";
import NuevaSalida from "./components/NuevaSalida";
import CataLogosAlmacen from "./components/CatalogosAlmacen";
import Actividad from "./components/Actividad";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";
import FeaturedPlayListTwoToneIcon from "@material-ui/icons//FeaturedPlayListTwoTone";
import AssignmentTwoTone from "@material-ui/icons/AssignmentTwoTone";
import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";
import CatalogosAlmacen from "./components/CatalogosAlmacen";

const MainAlmacen = () => {
  const menuOptions = [
    {
      opKey: "almacenExistencias",
      opLabel: "Existentes",
      opPath: "/almacen/existencias",
      opIcon: <ListAltTwoToneIcon />,
    },
    {
      opKey: "almacenEntradas",
      opLabel: "Nueva Entrada",
      opPath: "/almacen/nueva-entrada",
      opIcon: <AddTwoToneIcon />,
    },
    {
      opKey: "almacenSalidas",
      opLabel: "Salida",
      opPath: "/almacen/nueva-salida-items",
      opIcon: <AssignmentTwoTone />,
    },
    {
      opKey: "almacenActividad",
      opLabel: "Actividad",
      opPath: "/almacen/actividad",
      opIcon: <FeaturedPlayListTwoToneIcon />,
    },
    {
      opKey: "almacenCatalogos",
      opLabel: "Catálogos",
      opPath: "/almacen/catalogos",
      opIcon: <FeaturedPlayListTwoToneIcon />,
    },
  ];
  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  useEffect(() => {
    authenticatedUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs={2}>
            <SideBar menuOptions={menuOptions} />
          </Col>
          <Col xs={10}>
            <Header user={user} />
            <div>
              <main>
                <ItemsState>
                  <UsuarioState>
                    <Switch>
                      <PrivateRoute
                        path="/almacen/existencias"
                        component={Existentes}
                        roles={[roles.Almacen]}
                      />
                      <PrivateRoute
                        path="/almacen/nueva-entrada"
                        component={NuevaEntrada}
                        roles={[roles.Almacen]}
                      />
                      <PrivateRoute
                        path="/almacen/catalogos"
                        component={CatalogosAlmacen}
                        roles={[roles.Almacen]}
                      />
                      <PrivateRoute
                        path="/almacen/nueva-salida-items"
                        component={NuevaSalida}
                        roles={[roles.Almacen]}
                      />
                      <PrivateRoute
                        path="/almacen/actividad"
                        component={Actividad}
                        roles={[roles.Almacen]}
                      />
                    </Switch>
                  </UsuarioState>
                </ItemsState>
              </main>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MainAlmacen;
