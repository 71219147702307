import React from "react";
import Card from "react-bootstrap/Card";

const ExpandedPayment = ({ data }) => {
  return (
    <div style={{ paddingLeft: "50px" }}>
      {data ? (
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            Folio: {data.folio} <br />
            Pago realizado el {data.fecha_pago} <br />
            por: {data.alumno}, con matrícula {data.matricula} <br />
            la cantidad de {data.cantidad_pagada} ( Pesos M.N. ) <br />
            Por concepto de: {data.cobro} <br />
            Descuento aplicado: {data.descuento} <br />
          </Card.Body>
          <Card.Footer>
            Estado: {data.cancelado === false ? "Pagado" : "Cancelado"}
          </Card.Footer>
        </Card>
      ) : null}
    </div>
  );
};

export default ExpandedPayment;
