import React, { useReducer } from "react";

import server from "../../../../config/axios";

import ConceptoReducer from "./conceptoReducer";
import ConceptoContext from "./conceptoContext";

import {
  SET_MESSAGE,
  FETCHALL_CONCEPTS,
  FETCHALL_CAREERS,
  UNSET_MESSAGE,
} from "../../../../types";

const ConceptoState = (props) => {
  const initialState = {
    allconcepts: [],
    allCareers: [],
    message: null,
  };

  const [state, dispatch] = useReducer(ConceptoReducer, initialState);

  //Funciones

  const cleanAlertMessages = () => {
    setTimeout(() => {
      dispatch({
        type: UNSET_MESSAGE,
      });
    }, 3500);
  };

  const saveConcept = async (data) => {
    try {
      const response = await server.post("/createNewConcept", data);
      const alerta = {
        msg: response.data.msg,
        title: "Listo!",
        category: "success",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      getAllConcepts();
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al guardar el concepto";
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getAllConcepts = async () => {
    try {
      const response = await server.get("/getAllConcepts");
      dispatch({
        type: FETCHALL_CONCEPTS,
        payload: response.data,
      });
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los datos";
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const getAllCareers = async () => {
    try {
      const response = await server.get("/getAllCarrers");
      dispatch({
        type: FETCHALL_CAREERS,
        payload: response.data,
      });
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los datos";
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  const changeStatus = async (codigo) => {
    try {
      const response = await server.post("/changeConceptStatus", [codigo]);
      const alerta = {
        msg: response.data.msg,
        title: "Guardado",
        category: "success",
      };
      getAllConcepts();
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "No se pudo cambiar el estado del concepto " + codigo;
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    cleanAlertMessages();
  };

  return (
    <ConceptoContext.Provider
      value={{
        allconcepts: state.allconcepts,
        message: state.message,
        allCareers: state.allCareers,
        saveConcept,
        getAllConcepts,
        getAllCareers,
        changeStatus,
      }}
    >
      {props.children}
    </ConceptoContext.Provider>
  );
};

export default ConceptoState;
