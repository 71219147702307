import React, { useContext, useState, useEffect } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import PagoContext from "../context/pagos/pagoContext";

import ReciboPreview from "./ReciboPreview";

import print from "print-js";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";

import "bootstrap/dist/css/bootstrap.css";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

const NuevoPago = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { user, authenticatedUser } = authContext;

  const pagoContext = useContext(PagoContext);
  const {
    recibo,
    message,
    students,
    descuentos,
    cobros,
    filteredStudents,
    getStudentsList,
    filterStudents,
    getConcepts,
    savePayment,
    cleanReceipt,
  } = pagoContext;

  useEffect(() => {
    getStudentsList();
    getConcepts();
    authenticatedUser();

    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //state para iniciar sesión
  const [pagoNuevo, setPagoNuevo] = useState({
    filtroestudiante: "",
    estudiante: "",
    matricula: "",
    cantidad: 0.0,
    concepto: "",
    conceptoDesc: "",
    conceptoCant: 0.0,
    descuento: "",
    descuentoDesc: "",
    descuentoCant: 0.0,
    showModal: false,
    checked: false,
    descuentoInactivo: true,
  });

  //extraer del pago
  const {
    filtroestudiante,
    estudiante,
    matricula,
    concepto,
    conceptoDesc,
    conceptoCant,
    cantidad,
    descuento,
    descuentoDesc,
    descuentoCant,
    showModal,
    checked,
    descuentoInactivo,
  } = pagoNuevo;

  const onChangeStudentFilter = (e) => {
    setPagoNuevo({
      ...pagoNuevo,
      [e.target.name]: e.target.value,
    });

    filterStudents(e.target.value, students);
  };

  const onChangeSelectAlumno = (option) => {
    const alumno = students.find(
      (student) => student.matricula === option.value
    );
    setPagoNuevo({
      ...pagoNuevo,
      matricula: alumno.matricula,
      estudiante: alumno.nombre,
    });
  };

  const onChangeSelectPago = (option) => {
    const cpago = cobros.find((cobro) => cobro.codigo === option.value);
    setPagoNuevo({
      ...pagoNuevo,
      concepto: cpago.codigo,
      conceptoDesc: cpago.descripcion,
      conceptoCant: cpago.cantidad,
      cantidad: cpago.cantidad,
      descuentoInactivo: false,
    });
  };

  const onChangeSelectDescuento = (option) => {
    if (concepto.trim() === "") {
      showAlert(
        "No puedes agregar un descuento sin un pago",
        "Error",
        "danger"
      );
      return;
    }
    const adesc = descuentos.find(
      (descuento) => descuento.codigo === option.value
    );

    let newCant = conceptoCant - adesc.cantidad;

    if (newCant < 0) {
      newCant = 0;
    }

    setPagoNuevo({
      ...pagoNuevo,
      descuento: adesc.codigo,
      descuentoDesc: adesc.descripcion,
      descuentoCant: adesc.cantidad,
      cantidad: newCant,
    });
  };

  const onSubmitPayment = (e) => {
    e.preventDefault();
    if (
      estudiante.trim() === "" ||
      matricula.trim() === "" ||
      concepto.trim() === ""
    ) {
      showAlert("Llena los campos requeridos", "Error", "danger");
      return;
    }
    let usuario;
    if (user) {
      usuario = user.rfc;
    } else {
      return;
    }
    savePayment({
      concepto: concepto,
      descuento: descuento,
      usuario: usuario,
      matricula: matricula,
      sinRegistro: checked,
      estudiante: estudiante,
    });

    setPagoNuevo({
      ...pagoNuevo,
      showModal: true,
    });
  };

  // const onHideModal = (e) => {
  //   setPagoNuevo({
  //     ...pagoNuevo,
  //     showModal: false,
  //   });
  // };

  const setChecked = (e) => {
    setPagoNuevo({
      ...pagoNuevo,
      checked: !checked,
      estudiante: "",
      filtroestudiante: "",
      matricula: "",
    });
  };

  const handlePrint = (e) => {
    //Build a URL from the file
    const fileURL = URL.createObjectURL(recibo);
    //Open the URL on new Window
    //window.open(fileURL);
    print(fileURL);
    //print({ printable: recibo, type: "pdf", base64: true });
    setPagoNuevo({
      filtroestudiante: "",
      estudiante: "",
      matricula: "",
      cantidad: 0.0,
      concepto: "",
      conceptoDesc: "",
      conceptoCant: 0.0,
      descuento: "",
      descuentoDesc: "",
      descuentoCant: 0.0,
      showModal: false,
      checked: false,
      descuentoInactivo: true,
    });
    cleanReceipt();
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "Exito"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value="sinRegistro"
          id="sinRegistro"
          name="sinRegistro"
          checked={checked}
          onChange={setChecked}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          No registrado
        </label>
      </div>
      <div style={{ marginTop: "10%" }}>
        <Form>
          <Row className="text-center">
            <h5> Alumno </h5>
          </Row>
          {checked ? (
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  id="estudiante"
                  name="estudiante"
                  value={estudiante}
                  placeholder="Nombre Alumno"
                  onChange={onChangeStudentFilter}
                />
              </Col>
              <Col xs="4">
                <Form.Control
                  type="text"
                  id="matricula"
                  name="matricula"
                  value={matricula}
                  placeholder="Matricula"
                  onChange={onChangeStudentFilter}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="5" className="input-group mb-3">
                <span className="input-group-text">
                  <SearchTwoToneIcon />
                </span>
                <Form.Control
                  type="text"
                  id="filtroEstudiante"
                  name="filtroestudiante"
                  value={filtroestudiante}
                  placeholder="Busqueda"
                  onChange={onChangeStudentFilter}
                />
              </Col>
              <Col>
                <Select
                  id="estudiante"
                  name="estudiante"
                  onChange={onChangeSelectAlumno}
                  value={{ value: matricula, label: estudiante }}
                  options={filteredStudents.map((student) => ({
                    value: student.matricula,
                    label: student.nombre,
                  }))}
                  placeholder="Selecciona"
                />
              </Col>
            </Row>
          )}

          <Row>
            <div className="text-center">
              <h5> Concepto de pago </h5>
            </div>
          </Row>
          <Row>
            <Col>
              <Select
                id="concepto"
                name="concepto"
                onChange={onChangeSelectPago}
                placeholder="Selecciona"
                value={{ value: concepto, label: conceptoDesc }}
                options={cobros.map((cobro) => ({
                  value: cobro.codigo,
                  label: cobro.descripcion,
                }))}
              />
            </Col>
            <Col xs="2" className="input-group mb-3">
              <span className="input-group-text">$</span>
              <Form.Control
                readOnly={true}
                id="conceptoCant"
                name="conceptoCant"
                value={conceptoCant}
              ></Form.Control>
            </Col>
          </Row>
          <Row className="text-center">
            <h5> Descuento </h5>
          </Row>
          <Row>
            <Col>
              <Select
                id="descuento"
                name="descuento"
                onChange={onChangeSelectDescuento}
                placeholder="Selecciona"
                isDisabled={descuentoInactivo}
                value={{ value: descuento, label: descuentoDesc }}
                options={descuentos.map((descuento) => ({
                  value: descuento.codigo,
                  label: descuento.descripcion,
                }))}
              />
            </Col>
            <Col xs="2" className="input-group mb-3">
              <span className="input-group-text">-</span>
              <Form.Control
                readOnly={true}
                id="descuentoCant"
                name="descuentoCant"
                value={descuentoCant}
              ></Form.Control>
            </Col>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <Col style={{ left: "70vw" }}>
              <Button
                style={{ width: "100px" }}
                type="submit"
                onClick={onSubmitPayment}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <br />
      {estudiante.trim() === "" ? null : (
        <ReciboPreview
          estudiante={estudiante}
          matricula={matricula}
          cantidad={cantidad}
          concepto={conceptoDesc}
          descuento={descuentoDesc}
        />
      )}
      {recibo ? (
        <div>
          <Modal
            show={showModal}
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="sm"
          >
            <Modal.Header>
              <Modal.Title>Listo!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Pago guardado</Modal.Body>
            <Modal.Footer>
              <Button onClick={handlePrint}>Imprimir Recibo</Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default NuevoPago;
